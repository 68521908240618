/* eslint-disable react/no-multi-comp */
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { boardingGetCountries } from '../../../../../redux/actions/boardingActions';
import { FormattedMessage } from 'react-intl';
import { Field } from 'react-final-form';
import {
  Typography,
  FormControlLabel,
  Radio,
  TextField
} from '@material-ui/core';
import TextInput from '../../../../components/inputs/TextInput';
import RadioGroupInput from '../../../../components/inputs/RadioGroupInput';
import {
  composeValidators,
  required,
  noSpecialCharacters,
  hasLength,
  number,
  email,
  validDate
} from '../../../../../utils/validators/translated';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import DatePickerInput from '../../../../components/inputs/DatePickerInput';
import Media from 'react-media';
import SelectInput from '../../../../components/inputs/SelectInput';
import PropTypes from 'prop-types';

const mustClickNo = (value) =>
  (Boolean(value) && value !== 'no'
    ? <FormattedMessage id="applicationForm.personalData.isPEP.mustClickNo" />
    : undefined);

class PersonalDataFormPart extends React.Component {
  static propTypes = {
    countries: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    }).isRequired).isRequired,
    boardingGetCountriesAction: PropTypes.func.isRequired,
  };

  constructor () {
    super();

    const that = this;
    window.fillFormWithPropData = (prop) => {
      const keys = Object.keys(prop);
      keys.forEach(key => {
        that.props.form.change(key, prop[key]);
      });
    };
  }

  componentDidMount () {
    this.props.boardingGetCountriesAction();
  }

  render () {
    const {
      countries,
    } = this.props;

    return (
      <React.Fragment>
        <Grid container spacing={24}>
          <Grid item xs={12}>
            <Typography
              variant="subheading"
              className="subheading"
            >
              1. <FormattedMessage id="applicationForm.personalData.title" />
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={24}>
          <Grid item xs={12} sm={6}>
            <Field
              component={TextInput}
              className="text-field"
              name="firstName"
              label={<FormattedMessage id="applicationForm.personalData.firstName" />}
              validate={composeValidators(required, noSpecialCharacters)}
              validateFields={[]}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              component={TextInput}
              className="text-field"
              name="lastName"
              label={<FormattedMessage id="applicationForm.personalData.lastName" />}
              validate={composeValidators(required, noSpecialCharacters)}
              validateFields={[]}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              component={TextInput}
              className="text-field"
              name="oib"
              label={<FormattedMessage id="applicationForm.personalData.OIB" />}
              validate={composeValidators(required, number, hasLength(11))}
              validateFields={[]}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6} >
            <Media query="(max-width: 650px)">
              {matches =>
                (<Field
                  component={DatePickerInput}
                  className="date-picker"
                  name="dateOfBirth"
                  label={<FormattedMessage id="applicationForm.personalData.dateOfBirth" />}
                  placeholder={moment(Date.now()).subtract(30, 'years').format('DD/MM/YYYY')}
                  block
                  hideKeyboardShortcutsPanel
                  daySize={matches ? (45) : (50)}
                  validate={validDate}
                  validateFields={[]}
                  numberOfMonths={1}
                  firstDayOfWeek={1}
                  required
                  disableOpenOnEnter
                />)
              }
            </Media>
          </Grid>
          <Grid item xs={12}>
            <Field
              name="gender"
              className="form-control"
              groupClassName="radio-group"
              component={RadioGroupInput}
              validate={required}
              validateFields={[]}
              required
              label={<FormattedMessage id="applicationForm.personalData.gender.label" />}
            >
              <FormControlLabel
                value="male"
                control={<Radio />}
                label={<FormattedMessage id="applicationForm.personalData.gender.male" />}
              />
              <FormControlLabel
                value="female"
                control={<Radio />}
                label={<FormattedMessage id="applicationForm.personalData.gender.female" />}
              />
            </Field>
          </Grid>
        </Grid>
        <Grid container spacing={24}>
          <Grid item xs={2} sm={1}>
            <TextField
              className="text-field"
              label=" "
              value="+385"
              disabled
              onChange={() => null}
            />
          </Grid>
          <Grid item xs={2} sm={1}>
            <Field
              component={TextInput}
              className="text-field"
              name="phoneNumberRegionCode"
              label={<FormattedMessage id="applicationForm.personalData.phoneNumberRegionCode" />}
              validate={required}
              validateFields={[]}
              required
            />
          </Grid>
          <Grid item xs={8} sm={4}>
            <Field
              component={TextInput}
              className="text-field"
              name="phoneNumber"
              label={<FormattedMessage id="applicationForm.personalData.phoneNumber" />}
              validate={required}
              validateFields={[]}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              component={TextInput}
              className="text-field"
              name="email"
              label={<FormattedMessage id="applicationForm.personalData.email" />}
              validate={composeValidators(required, email)}
              validateFields={[]}
              required
            />
          </Grid>
        </Grid>

        <Grid container spacing={24}>
          <Grid item xs={12} sm={6}>
            <Field
              component={TextInput}
              className="text-field"
              name="placeOfBirth"
              label={<FormattedMessage id="applicationForm.personalData.placeOfBirth" />}
              validate={composeValidators(required, noSpecialCharacters)}
              validateFields={[]}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              component={SelectInput}
              className="text-field"
              name="citizenship"
              label={<FormattedMessage id="applicationForm.personalData.citizenship" />}
              helperText={<FormattedMessage id="applicationForm.personalData.citizenship.description" />}
              validate={required}
              validateFields={[]}
              required
            >
              {countries.length === 0 && <option key={`citizenship_${191}`} value={191}>HRVATSKA</option>}
              {countries.map(e => (<option key={`citizenship_${e.id}`} value={e.id}>{e.name}</option>))}
            </Field>
          </Grid>
        </Grid>

        <Grid container spacing={24}>
          <Grid item xs={12}>
            <Field
              name="isPEP"
              className="form-control"
              groupClassName="radio-group"
              component={RadioGroupInput}
              validate={composeValidators(
                required,
                mustClickNo
              )}
              helperText={<FormattedMessage id="applicationForm.personalData.isPEP.description" />}
              validateFields={[]}
              required
              label={<FormattedMessage id="applicationForm.personalData.isPEP" />}
            >
              <FormControlLabel
                value="yes"
                control={<Radio />}
                label={<FormattedMessage id="applicationForm.yes" />}
              />
              <FormControlLabel
                value="no"
                control={<Radio />}
                label={<FormattedMessage id="applicationForm.no" />}
              />
            </Field>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  countries: state.boarding.countries,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  boardingGetCountriesAction: boardingGetCountries,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PersonalDataFormPart);
