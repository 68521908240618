import { getFormattedDateTime } from '../../utils/dates';

export const SAVE_FUEL_SAVINGS = 'SAVE_FUEL_SAVINGS';
export const CALCULATE_FUEL_SAVINGS = 'CALCULATE_FUEL_SAVINGS';

// example of a thunk using the redux-thunk middleware
export const saveFuelSavings = (settings) => (dispatch) =>
  // thunks allow for pre-processing actions, calling apis, and dispatching multiple actions
  // in this case at this point we could call a service that would persist the fuel savings
  dispatch({
    type: SAVE_FUEL_SAVINGS,
    dateModified: getFormattedDateTime(),
    settings
  });

export const calculateFuelSavings = (settings, fieldName, value) => ({
  type: CALCULATE_FUEL_SAVINGS,
  dateModified: getFormattedDateTime(),
  settings,
  fieldName,
  value
});
