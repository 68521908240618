import React from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import { GridListTile, GridListTileBar, IconButton } from '@material-ui/core';
import PropTypes from 'prop-types';

const FileUploadPreview = ({ file, onRemove }) => (
  <GridListTile item xs={6} sm={3} md={2}>
    <img
      src={file.preview}
      alt={file.name}
      className="file-upload__file-preview"
    />

    <GridListTileBar
      title={file.name}
      subtitle={`${file.size} bytes`}
      actionIcon={
        <IconButton
          aria-label="Delete"
          onClick={onRemove}
          className="file-upload__delete-button"
        >
          {/*Sorry for the inline style, I couldn't BEM around it*/}
          <DeleteIcon style={{
            color: 'rgba(255, 255, 255, 0.54)',
          }}
          />
        </IconButton>
      }
    />
  </GridListTile>
);

FileUploadPreview.propTypes = {
  file: PropTypes.shape({}).isRequired,
  onRemove: PropTypes.func.isRequired
};

export default FileUploadPreview;
