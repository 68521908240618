import React from 'react';
import { Link } from 'react-router-dom';
import { Paper } from '@material-ui/core';

const NotFoundPage = () => (
  <Paper className="content">
    <h4>
      404 Page Not Found
    </h4>
    <Link to="/"> Go back to homepage </Link>
  </Paper>
);

export default NotFoundPage;
