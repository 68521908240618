import React from 'react';
import { Button, Paper } from '@material-ui/core';
import CardApplicationForm from '../CardApplicationForm/index';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { boardingResetForm } from '../../../../redux/actions/boardingActions';

class CardApplicationPage extends React.Component {
  static propTypes = {
    formData: PropTypes.shape({}),
    boardingResetFormAction: PropTypes.func.isRequired,
  };

  renderNode (accessor, root) {
    const inRoot = accessor.length === 0;
    const data = inRoot ? root : _.get(root, accessor);
    if (_.isObject(data)) {
      return Object.keys(data).map(key => this.renderNode(`${accessor}${inRoot ? '' : '.'}${key}`, root));
    }

    return (
      <React.Fragment key={`div${accessor}`}>
        <dt key={`key${accessor}`}><b>{accessor}</b></dt>
        <dd key={`value${accessor}`}>{data}</dd>
      </React.Fragment>
    );
  }

  render () {
    const {
      formData,
      boardingResetFormAction,
    } = this.props;

    return (
      <Paper className="content">
        <div>
          {!formData &&
            <CardApplicationForm />
          }
          {formData &&
            <React.Fragment>
              <Button
                type="button"
                color="primary"
                variant="raised"
                onClick={boardingResetFormAction}
              >
                Reset
              </Button>
              <dl>
                {this.renderNode('', formData)}
              </dl>
            </React.Fragment>
          }
        </div>
      </Paper>
    );
  }
}

const mapStateToProps = state => ({
  formData: state.boarding.formData
});

const mapDispatchToProps = dispatch => bindActionCreators({
  boardingResetFormAction: boardingResetForm,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CardApplicationPage);
