import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  InputAdornment
} from '@material-ui/core';
import classNames from 'classnames';
import {fieldPropTypes} from '../../types/form';
import './style.scss';
import AsyncSelect from 'react-select/async';

// const loadOptions = async (inputText, callback)=>{
//     const response = await fetch('https://apid.dinersclub.si/onboarding/config');
//     const data = await response.json();
//      const logData =  data.lists.filter(i => i.name == "TypesOfEmployment");
//      const arrayData = logData[0].values.map(item => item);
//      console.log("API", arrayData);
//     return arrayData.map(i => ({label: i.name, value:i.id}));
//     // callback(arrayData.map(i => ({label: i.name, value:i.id})));


//   }
// let data = loadOptions();


const onChange = (input, context, originalOnChange, validationProps) => (e) => {
  if (input.name === 'firstName') {
    context.updateValue('clientName', e.target.value);
  }
  if (input.name === 'vatNumber') {
    let targetValue = e.target.value
    if (targetValue.length == 0 || (targetValue.length <= 8 && /^\d+$/.test(targetValue))) {
      originalOnChange(targetValue)
    }
  } else {
    originalOnChange(e.target.value);
  }
};
const SelectJobs = ({
                     input,
                     label,
                     dataValue,
                     placeholder,
                     onChangeData,
                     helperText,
                     meta: {touched, error},
                     inputProps,
                     validationProps,
                     className,
                     tooltip,
                     context,
                     data,
                     prefixText,
                     ...custom
                   }) => (

     
                  
  <FormControl
    error={touched && Boolean(error)}
    {...custom}
    className={classNames('form-control', className)}
  >
    <InputLabel className="form-control__label" shrink htmlFor={custom.id}>{label}</InputLabel>
 {console.log("VAlue",onChangeData)}
 {console.log("dataValue",dataValue)}
    <AsyncSelect
    defaultOptions
   
    loadOptions={data}
    />
    <FormHelperText className="form-control__helper">{(touched && error) || helperText || ' '}</FormHelperText>
  </FormControl>
);

SelectJobs.propTypes = {
  ...fieldPropTypes,
  className: PropTypes.string,
  prefixText: PropTypes.string,
  label: PropTypes.node.isRequired,
  inputProps: PropTypes.shape({}),
  validationProps: PropTypes.shape({}),
  helperText: PropTypes.node,
  tooltip: PropTypes.node,
  placeholder: PropTypes.node,
  context: PropTypes.shape({})
};

export default SelectJobs;
