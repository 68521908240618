import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import './ApprovedAmount.scss';
import myData from '../../../resources/locales/locale.sl.json';




class ApprovedAmount extends React.Component {
  static propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.arrayOf(PropTypes.shape({}).isRequired)
  };

  constructor (props) {
    super(props);
  }



  render () {
    let { value,amount } = this.props;
    return (
    
     <div className="mainContiner">
         <div className="amountText">
         <div dangerouslySetInnerHTML={{__html: myData['applicationForm.amount.approved.text']}} ></div>
         <div className='numberSpan'>{amount + ' €*' }</div>
         </div>
         <div className="amountInfo">
         <FormattedMessage id="applicationForm.amount.approved.info" />
         </div>
     </div>
     
    );
  }
}


export default ApprovedAmount;
