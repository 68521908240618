import React from 'react';
import { MobileStepper, Button, CircularProgress } from '@material-ui/core';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const WizardMobileStepper = ({ steps, activeStep, onBack, submitting }) => (
  <MobileStepper
    variant="progress"
    steps={steps}
    position="static"
    activeStep={activeStep}
    nextButton={(
      <Button size="small" type="submit" disabled={submitting}>
        <FormattedMessage id={activeStep + 1 === steps ? 'applicationForm.submit' : 'applicationForm.next'} />
        {submitting && (
          <CircularProgress
            className="progress"
            size={20}
          />
        )}
      </Button>
    )}
    backButton={(
      <Button size="small" onClick={onBack} disabled={activeStep === 0}>
        <FormattedMessage id="applicationForm.previous" />
      </Button>
    )}
  />
);

WizardMobileStepper.propTypes = {
  activeStep: PropTypes.number,
  steps: PropTypes.number.isRequired,
  onBack: PropTypes.func.isRequired,
  submitting: PropTypes.bool
};

export default WizardMobileStepper;
