import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Field } from 'react-final-form';
import TextInput from '../../../../components/inputs/TextInput';
import {
  Typography,
  FormControlLabel,
  Radio
} from '@material-ui/core';
import RadioGroupInput from '../../../../components/inputs/RadioGroupInput';
import {
  composeValidators,
  required,
  hasLength,
  noSpecialCharacters,
  number,
  validCroatianIban,
  validDate
} from '../../../../../utils/validators/translated';
import Grid from '@material-ui/core/Grid';
import DatePickerInput from '../../../../components/inputs/DatePickerInput';
import Media from 'react-media';

const IncomeDataFormPart = () => (
  <React.Fragment>
    <Grid container spacing={24}>
      <Grid item xs={12}>
        <Typography
          className="subheading"
          variant="subheading"
        >
          5. <FormattedMessage id="applicationForm.incomeData.title" />
        </Typography>
      </Grid>
    </Grid>
    <Grid container spacing={24}>
      <Grid item xs={12}>
        <Field
          name="contractDurationType"
          className="form-control"
          groupClassName="radio-group"
          component={RadioGroupInput}
          validate={required}
          validateFields={[]}
          required
          label={<FormattedMessage id="applicationForm.incomeData.contractDurationType" />}
        >
          <FormControlLabel
            value="indefinite"
            control={<Radio />}
            label={<FormattedMessage id="applicationForm.incomeData.contractDurationType.indefinite" />}
          />
          <FormControlLabel
            value="specified"
            control={<Radio />}
            label={<FormattedMessage id="applicationForm.incomeData.contractDurationType.specified" />}
          />
        </Field>
      </Grid>
      <Field name="contractDurationType" subscription={{ value: true }}>
        {({ input: { value: contractDurationType } }) => (
          contractDurationType === 'specified' && (
            <Grid item xs={12}>
              <Media query="(max-width: 650px)">
                {(matches) => (
                  <Field
                    component={DatePickerInput}
                    className="date-picker"
                    name="contractDurationEndDate"
                    label={<FormattedMessage id="applicationForm.incomeData.contractDurationEndDate" />}
                    block
                    hideKeyboardShortcutsPanel
                    daySize={matches ? (45) : (50)}
                    validate={validDate}
                    validateFields={[]}
                    numberOfMonths={1}
                    firstDayOfWeek={1}
                    required
                    disableOpenOnEnter
                  />)
                }
              </Media>
            </Grid>
          )
        )}
      </Field>
      <Grid item xs={12}>
        <Field
          component={TextInput}
          className="text-field"
          name="employerOIB"
          label={<FormattedMessage id="applicationForm.incomeData.employerOIB" />}
          validate={composeValidators(required, number, hasLength(11))}
          validateFields={[]}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          component={TextInput}
          className="text-field"
          name="jobTitle"
          label={<FormattedMessage id="applicationForm.incomeData.jobTitle" />}
          validate={composeValidators(required, noSpecialCharacters)}
          validateFields={[]}
          required
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field
          component={TextInput}
          className="text-field"
          name="yearsWithCurrentEmployer"
          validate={number}
          label={<FormattedMessage id="applicationForm.incomeData.yearsWithCurrentEmployer" />}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field
          component={TextInput}
          className="text-field"
          name="yearsOfExperience"
          label={<FormattedMessage id="applicationForm.incomeData.yearsOfExperience" />}
          validate={composeValidators(required, number)}
          validateFields={[]}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          component={TextInput}
          className="text-field"
          name="IBAN"
          label={<FormattedMessage id="applicationForm.incomeData.IBAN" />}
          validate={composeValidators(required, validCroatianIban)}
          validateFields={[]}
          required
        />
      </Grid>
    </Grid>
  </React.Fragment>
);

export default IncomeDataFormPart;
