import React from 'react';
import PropTypes from 'prop-types';
import {fieldPropTypes} from '../../types/form';
import classNames from 'classnames';
import {FormControl, InputLabel, Input, FormHelperText, Tooltip} from '@material-ui/core';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import './style.scss';

const initialValues = {
  //dateOfBirth: moment(Date.now()).hours(0).subtract(30, 'years')
};

const CustomDateInput = React.forwardRef(({ value, format, setter, dateFormat, onChange, ...props }, ref) => {

  return <input
    label={format}
    key={value}
    onChange={(e) => {
      const numericValue = parseInt(e.target.value);
      const minLenght = format === 'YYYY' ? 4 : 1;

      if (numericValue > 0 && numericValue.toString().length >= minLenght) {
        const newValue = moment(value || moment(), dateFormat).set(setter, numericValue - (setter === 'month' ? 1 : 0)).format(dateFormat);

        onChange({
          target: {
            value: newValue
          }
        });
      }

    }}
    defaultValue={value && moment(value, dateFormat).format(format)}
    {...props}
    // onBlur={(e) => e.target.value = value && moment(value, dateFormat).format(format)}
    type="number"
  />
});

const DayPickerCustom = (props) => {
  const {context, inputRef, onChange, className, dateFormat, value, ...other} = props;

  let disableChange = false;

  const onChangeDateCustom = ctx => (e) => {

    if (ctx) {
      let newDate = e;

      onChange(newDate);
      ctx.updateValue('dateOfBirth', newDate);
    }
  };

  let customInputFormatter = '';
  let customInputSetter = '';

  switch (other.idModifier) {
    case 'y':
      customInputFormatter = 'YYYY';
      customInputSetter = 'year';
      break;
    case 'm':
      customInputFormatter = 'MM';
      customInputSetter = 'month';
      break;
    case 'd':
      customInputFormatter = 'DD';
      customInputSetter = 'date';
      break;
  }

  return (
    <DatePicker
      {...other}
      ref={inputRef}
      id={`${other.name}${other.idModifier || ''}`}
      onChange={onChangeDateCustom(context)}
      selected={(value !== '' && value.isValid()) ? value : null}
      className={className}
      showYearDropdown
      showMonthDropdown
      disabledKeyboardNavigation
      dropdownMode="select"
      dateFormatCalendar="MMMM"
      // onChangeRaw={(event) => {
      //   if (value) {
      //     let targetValue = parseInt(event.target.value || 1);
      //     const v = moment(value);

      //     let newDate;
      //     switch (dateFormat) {
      //       case 'D':
      //         newDate = v.day(targetValue);
      //         break;
      //       case 'M':
      //         newDate = v.month(targetValue - 1);
      //         break;
      //       case 'YYYY':
      //         newDate = v.year(targetValue);
      //         break;
      //       }

      //     // console.log({
      //     //   type: 'rawChange',
      //     //   dateFormat,
      //     //   value: value.format('YYYY-MM-DD'),
      //     //   newValue: newDate.format('YYYY-MM-DD')
      //     // });

      //     if (newDate) {
      //       onChangeDateCustom(context)(newDate, true);
      //     }
      //   }
      // }}
      shouldCloseOnSelect={false}
      placeholderText={other.placeholder}
      dateFormat={dateFormat}
      customInput={(
        <CustomDateInput
          // key={value && value.format(dateFormat)}
          dateFormat={dateFormat}
          format={customInputFormatter}
          setter={customInputSetter}
        />
      )}
    />
  );
};

DayPickerCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.oneOf([''])
  ]),
  context: PropTypes.shape({}),
  dateFormat: PropTypes.string,
};

// eslint-disable-next-line react/no-multi-comp
// noinspection JSAnnotator
const DatePickerInput = ({
                           input,
                           label,
                           helperText,
                           meta: {touched, error},
                           placeholder,
                           className,
                           context,
                           tooltip,
                           dateFormat,
                           showValidationErrors,
                           ...custom
                         }) => (
  <FormControl
    className={classNames('form-control', className)}
    error={touched && Boolean(error)}
  >
    <InputLabel className={classNames('form-control__label', {
      'MuiFormLabel-filled-datepicker': input.value
    })} shrink htmlFor={custom.id}>{label}</InputLabel>
    <Input
      className="form-control__input date"
      placeholder={placeholder}
      inputComponent={DayPickerCustom}
      disableUnderline
      autoComplete={'off'}
      inputProps={{
        context,
        dateFormat,
        ...input,
        ...custom
      }}
      id={input.name}
    />
    { (dateFormat === 'MMMM' && !showValidationErrors) &&
      <FormHelperText className="form-control__helper date">{(touched && error) || helperText || ' '}</FormHelperText>
    }
    { (dateFormat !== 'MMMM' && showValidationErrors) &&
      <FormHelperText className="form-control__helper">{(touched && error) || helperText || ' '}</FormHelperText>
    }
  </FormControl>
);

DatePickerInput.propTypes = {
  ...fieldPropTypes,
  label: PropTypes.node.isRequired,
  className: PropTypes.string,
  tooltip: PropTypes.node,
  showValidationErrors: PropTypes.bool,
  helperText: PropTypes.node,
  context: PropTypes.shape({}),
  dateFormat: PropTypes.string,
};

export default DatePickerInput;
