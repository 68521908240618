import React from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormGroup,
  Checkbox
} from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import classNames from 'classnames';
import { fieldPropTypes } from '../../types/form';
import './style.scss';

const onChange = (originalOnChange) => (e) => originalOnChange(e.target.checked);

const onTriStateChange = (originalOnChange, previousValue) => () => {
  if (previousValue === true) {
    originalOnChange(false);
  } else if (previousValue === false) {
    originalOnChange(null);
  } else {
    originalOnChange(true);
  }
};

const CheckboxInput = ({
  input,
  label,
  helperText,
  meta: { touched, error },
  className,
  triState,
  ...rest
}) => (
  <FormControl
    component="fieldset"
    error={touched && Boolean(error)}
    className={classNames('form-control', className)}
  >
    <FormGroup className="form-control__radio-group">
      <FormControlLabel
        control={
          <Checkbox
            checkedIcon={<CheckBoxIcon className="form-control__square-radio--checked" />}
            icon={<CheckBoxOutlineBlankIcon />}
            checked={Boolean(input.value)}
            onChange={(triState ? onTriStateChange : onChange)(input.onChange, input.value)}
            className="form-control__square-radio"
            indeterminate={triState ? input.value !== true && input.value !== false : false}
            value={input.value.toString()}
            {...rest}
          />
        }
        label={label}
      />
      <FormHelperText className="form-control__helper">
        {(touched && error) || helperText}{' '}
      </FormHelperText>
    </FormGroup>
  </FormControl>
);


/*
(
  <FormControl
    component="fieldset"
    error={touched && Boolean(error)}
    {...rest}
    className={classNames('form-control', className)}
  >
    {label &&
      <FormLabel component="legend" className={classNames('form-control__label', labelClassName)}>
        {label}
      </FormLabel>
    }
    <RadioGroup
      {...input}
      onChange={onChange(input.onChange)}
      className={classNames('form-control__radio-group', groupClassName)}
    >
      {children}
    </RadioGroup>
    <FormHelperText className="form-control__helper">{(touched && error) || helperText || ' '}</FormHelperText>
  </FormControl>
);
*/

CheckboxInput.propTypes = {
  ...fieldPropTypes,
  label: PropTypes.node.isRequired,
  groupClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  className: PropTypes.string,
  helperText: PropTypes.node,
  triState: PropTypes.bool
};

export default CheckboxInput;
