import {
  sendBoardingForm,
  getCountries,
  sendPartialBoardingForm,
} from '../../api/boarding';
import queryString from 'query-string';
import cookies from 'react-cookies';
import ReactGA from 'react-ga';

export const BOARDING_FINISH_FORM = 'BOARDING_FINISH_FORM';
export const BOARDING_GET_COUNTRIES_FINISHED = 'BOARDING_GET_COUNTRIES_FINISHED';
export const BOARDING_MODAL_POPUP = 'BOARDING_MODAL_POPUP';

export const submitOnboardingForm = (formData) => async (dispatch) => {
  try {
    const response = await sendBoardingForm(formData);

    return dispatch({
      type: BOARDING_FINISH_FORM,
      data: response
    });
  } catch (e) {
    return null;
  }
};

export const openModal = (isOpen) => async (dispatch) => {
  try {
    let isOpenModal = isOpen;

    return dispatch({
      type: BOARDING_MODAL_POPUP,
      data: isOpenModal
    });
  } catch (e) {
    return null;
  }
};

const yesNoToBoolean = (value) => {
  if (value === 'yes') {
    return true;
  } else if (value === 'no') {
    return false;
  }
  return false; // temporarily
};

export const submitPartialOnboardingForm = (formData) => async (dispatch) => {
  try {
    // eslint-disable-next-line no-unused-vars
    const { termsAndConditions, ...restData } = formData;
    const { akcijaId, prodId } = queryString.parse(location.search);

    restData.akcijaId = akcijaId;
    restData.isPensioner = yesNoToBoolean(restData.isPensioner);
    restData.productId = Number(prodId);

    /* eslint-disable camelcase */
    const {
      utm_source,
      utm_medium,
      utm_campaign,
      utm_content,
      utm_keyword,
      utm_term
    } = queryString.parse(location.search);
    const cookie = cookies.load('__utmzz');
    const cookieValue = {};
    if (cookie) {
      const items = cookie.split('|');
      items.forEach((item) => {
        const parts = item.split('=');
        if (parts && parts.length > 1) {
          /* eslint-disable prefer-destructuring */
          cookieValue[parts[0]] = parts[1];
          /* eslint-enable prefer-destructuring */
        }
      });
    }
    restData.utmSource = utm_source ? utm_source : cookieValue.utmcsr;
    restData.utmMedium = utm_medium ? utm_medium : cookieValue.utmcmd;
    restData.utmCampaign = utm_campaign ? utm_campaign : cookieValue.utmccn;
    restData.utmContent = utm_content ? utm_content : cookieValue.utmcct;
    restData.utmKeyword = utm_keyword;
    restData.utmTerm = utm_term ? utm_term : cookieValue.utmctr;
    /* eslint-enable camelcase */

    ReactGA.event({
      category: 'JADE',
      action: 'Request',
      label: 'Submit button'
    });
    const response = await sendPartialBoardingForm(restData);

    return dispatch({
      type: BOARDING_FINISH_FORM,
      data: response
    });
  } catch (e) {
    return null;
  }
};

export const boardingResetForm = () => ({
  type: BOARDING_FINISH_FORM,
  data: null,
});

export const boardingGetCountries = () => async (dispatch) => {
  try {
    const response = await getCountries();

    return dispatch({
      type: BOARDING_GET_COUNTRIES_FINISHED,
      data: response.data,
    });
  } catch (e) {
    return null;
  }
};
