import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Button, CircularProgress } from '@material-ui/core';
import { Form, FormSpy } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import createDecorator from 'final-form-focus';
import WebPhaseFormPart, { phonePrefix } from './FormParts/WebPhaseFormPart';
import HeaderCard from '../../components/HeaderCard';
import '../CardApplication/CardApplicationForm/style.scss';
import './style.scss';
import ReactGA from 'react-ga';
import uuid from 'uuid/v4';
import { referrerDataPropTypes } from '../../types/form';
import PreSignupContext from './PreSignpupContext';

const initialValues = {
  citizenship: 191
};

const focusOnErrors = createDecorator();

class PreSignupForm extends React.Component {
  static propTypes = {
    submitPartialOnboardingForm: PropTypes.func.isRequired,
    initialReferralCode: PropTypes.string,
    initialReferralCode2: PropTypes.string,
    referrerData: referrerDataPropTypes,
    referrerData2: referrerDataPropTypes,
    getReferrerData: PropTypes.func.isRequired
  };

  constructor (props) {
    super(props);
    this.initialValues = {
      referralCode: props.initialReferralCode,
      referralCode2: props.initialReferralCode2,
      ...initialValues
    };
  }

  componentDidMount = () => {
    if (localStorage && localStorage.getItem('userId')) {
      this.userId = localStorage.getItem('userId');
    } else {
      this.userId = uuid();
      if (localStorage) {
        localStorage.setItem('userId', this.userId);
      }
    }

    ReactGA.set({ userId: this.userId });
    ReactGA.pageview('/hr/zelim-diners');
  }

  handleSubmit = (formData) => {
    const { submitPartialOnboardingForm } = this.props;

    // eslint-disable-next-line no-unused-vars
    const { termsAndConditions, ...restData } = formData;

    restData.userId = this.userId;

    // restData.phoneNumberCountryCode = '+386';
    // const phoneParts = restData.phoneNumberPrefix.split(' ');
    // restData.phoneNumberPrefix = phoneParts[1];
    // restData.phoneNumberCountryCode = phoneParts[0];

    restData.phoneNumberPrefix = restData.phoneNumberPrefix;
    restData.phoneNumberCountryCode = phonePrefix.replace(/\+/igm, '');

    const { dateOfBirthDay: day, dateOfBirthMonth: month, dateOfBirthYear: year } = restData;
    restData.dateOfBirth = moment({ years: year, months: month - 1, days: day });
    delete restData.dateOfBirthDay;
    delete restData.dateOfBirthMonth;
    delete restData.dateOfBirthYear;

    if (restData.dateOfBirth) {
      restData.dateOfBirth = moment(restData.dateOfBirth).add(12, 'hours');
    }

    return submitPartialOnboardingForm(restData);
  }

  validateForm = (values) => {

    let errors = {};
    const { dateOfBirthDay: day, dateOfBirthMonth: month, dateOfBirthYear: year } = values;

    if (day && month && year) {
      const date = moment({ years: year, months: month - 1, days: day });

      if (!date.isValid()) {
        errors.dateOfBirthError = 'applicationForm.validation.invalidDate';
        errors.dateOfBirthDay = errors.dateOfBirthMonth = errors.dateOfBirthYear = null;
      } else if (moment().diff(date, 'years') < 18) {        errors = {};
        errors.dateOfBirthError = 'applicationForm.validation.over18';
        errors.dateOfBirthDay = errors.dateOfBirthMonth = errors.dateOfBirthYear = null;
      } else {
        errors = {};
        errors.dateOfBirthError = errors.dateOfBirthDay = errors.dateOfBirthMonth = errors.dateOfBirthYear = undefined;
      }
    }

    return errors;
  }

  render () {
    const { referrerData, referrerData2, getReferrerData } = this.props;
    
    return (
      <PreSignupContext.Consumer>
        {(context) => (<Form
          onSubmit={this.handleSubmit}
          decorators={[focusOnErrors]}
          initialValues={this.initialValues}
          subscription={{ values: true }}
          validate={this.validateForm}
        >
          {({ handleSubmit, form, values }) => (
            <form
              onSubmit={handleSubmit}
              className="card-application-form presignup-form"
            >
              <Grid container spacing={24}>
                <Grid item xs={12}>
                  <HeaderCard>
                    <HeaderCard.Title className="nopadding">
                      <h1 className="presignup-form__title">
                        <FormattedMessage id="applicationForm.title"/>
                      </h1>
                    </HeaderCard.Title>
                  </HeaderCard>
                </Grid>
              </Grid>
              <div className="presignup-form__content">
                <WebPhaseFormPart
                  change={form.change}
                  context={context}
                  values={values}
                  referrerData={referrerData}
                  referrerData2={referrerData2}
                  getReferrerData={getReferrerData}
                />
                <Grid container spacing={24} justify="flex-start" alignItems={"flex-start"}>
                  <Grid item xs={12} lg={6}>
                    <FormSpy subscription={{ submitting: true }}>
                      {({ submitting }) => (
                        <Button
                          className="text-field presignup-form__button"
                          type="submit"
                          color="primary"
                          variant="raised"
                          disabled={submitting}
                        >
                          <FormattedMessage id="applicationForm.submit"/>
                          {submitting && (
                            <CircularProgress className="progress" size={30}/>
                          )}
                          <i className="arrow right"/>
                        </Button>
                      )}
                    </FormSpy>
                  </Grid>
                </Grid>
              </div>
            </form>
          )}
        </Form>)}
      </PreSignupContext.Consumer>
    );
  }
}


export default PreSignupForm;
