import React from 'react';
import { FormattedMessage } from 'react-intl';
import * as common from './common';

export const required = common.required(
  <FormattedMessage id="applicationForm.validation.required" />);

export const mustBeYes = common.mustBeYes(
  <FormattedMessage id="applicationForm.validation.empty" />);

export const mustBeNo = common.mustBeNo(
  <FormattedMessage id="applicationForm.validation.empty" />);

export const maxLength = (max) => common.maxLength(
  max,
  <FormattedMessage id="applicationForm.validation.maxLength" values={{ max }} />);

export const minLength = (min) => common.minLength(
  min,
  <FormattedMessage id="applicationForm.validation.minLength" values={{ min }} />);

export const hasLength = (length) => common.hasLength(
  length,
  <FormattedMessage id="applicationForm.validation.hasLength" values={{ length }} />);

export const validSlovenianVAT = common.validSlovenianVAT(
  <FormattedMessage id="applicationForm.validation.invalidVAT" />);

export const number = common.number(
  <FormattedMessage id="applicationForm.validation.number" />);

export const noSpecialCharacters = common.noSpecialCharacters(
  <FormattedMessage id="applicationForm.validation.invalidCharacters" />);

export const validCroatianIban = common.validCroatianIban(
  <FormattedMessage id="applicationForm.validation.croatianIban" />);

export const email = common.email(
  <FormattedMessage id="applicationForm.validation.email" />);

export const validDateString = common.validDateString(
  <FormattedMessage id="applicationForm.validation.invalidDate" />);

export const validDate = common.validDate(
  <FormattedMessage id="applicationForm.validation.invalidDate" />);

export const mustBeAfter = (date) => common.mustBeAfter(
  date,
  <FormattedMessage id="applicationForm.validation.invalidDate" />);

// eslint-disable-next-line prefer-destructuring
export const composeValidators = common.composeValidators;
