import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { injectIntl, intlShape } from 'react-intl';
import PreSignupContext from './PreSignpupContext';
import queryString from 'query-string';
import PreSignupForm from './PreSignupForm';
import PreSignupSideInfo from './PreSignupSideInfo';
import FormSuccessNew from './FormSuccessNew';
import { submitPartialOnboardingForm } from '../../../redux/actions/boardingActions';
import * as referralActions from '../../../redux/actions/referralActions';
import { referrerDataPropTypes } from '../../types/form';
import FormFailNew from './FormFailNew';
import Modal from '@material-ui/core/Modal';
import ReactMarkdown from 'react-markdown';

import ImageCitizenship from '../../../resources/images/sidebar_citizenship.svg';
import ImagePolitical from '../../../resources/images/sidebar_political.svg';


class PreSignupFormPage extends React.Component {
  static propTypes = {
    formData: PropTypes.shape({}),
    intl: intlShape.isRequired,
    location: PropTypes.shape({
      search: PropTypes.string
    }).isRequired,
    referrerData: referrerDataPropTypes,
    referrerData2: referrerDataPropTypes,
    getReferrerData: PropTypes.func.isRequired,
    submitForm: PropTypes.func.isRequired
  };

  constructor (props) {
    super(props);
    this.state = {
      employeeType: 'default',
      status: '',
      imageUrl: 'sidebar_default.svg',
      imageUrlType: 'default',
      modalOpen: false,
      modalSlovenianOpen: false,
      clientName: '',
      dateOfBirth: ''
    };
  }

  componentDidMount () {
    const { getReferrerData, location: { search } } = this.props;
    const { referralCode, referralCode2 } = queryString.parse(search);
    if (referralCode) {
      getReferrerData(referralCode, 'referralCode');
    }
    if (referralCode2) {
      getReferrerData(referralCode2, 'referralCode2');
    }
  }

  componentDidUpdate (prevProps) {
    const { getReferrerData, location: { search } } = this.props;
    const { referralCode, referralCode2 } = queryString.parse(search);
    const {
      referralCode: prevReferralCode,
      referralCode2: prevReferralCode2
    } = queryString.parse(prevProps.location.search);
    if (referralCode && prevReferralCode !== referralCode) {
      getReferrerData(referralCode, 'referralCode');
    }
    if (referralCode2 && prevReferralCode2 !== referralCode2) {
      getReferrerData(referralCode2, 'referralCode2');
    }
  }

  handleClose = () => {
    this.setState({ modalOpen: false });
  }
  handleSlovenianClose = () => {
    this.setState({ modalSlovenianOpen: false });
  }

  fm (message) {
    return this.props.intl.formatMessage({ id: message });
  }

  updateValue = (key, val) => {
    console.log("VAL :", val);
    if (key === 'employeeType') {
      if (val !== 'political') {
        this.setState({
          imageUrl: 'sidebar_money.svg',
          imageUrlType: 'money'
        });
      } else {
        this.setState({
          imageUrl: 'sidebar_political.svg',
          imageUrlType: 'political'
        });
      }
    }
    this.setState({ [key]: val });
  }

  render () {
    const {
      formData,
      referrerData,
      referrerData2,
      getReferrerData,
      submitForm,
      location: { search }
    } = this.props;

    const { referralCode, referralCode2 } = queryString.parse(search);

    let content = null;

    if (!formData) {
      content = (
        <Grid container spacing={0} className={'presignup-container'}>
            <Grid item xs={12} sm={6} className={'presignup-sideinfo-container'}>
            <PreSignupContext.Provider value={{
              state: this.state,
              updateValue: this.updateValue
            }}
            >
              <PreSignupSideInfo/>
            </PreSignupContext.Provider>
          </Grid>
          <Grid item xs={12} sm={6} className={'presignup-form-container'}>
            <PreSignupContext.Provider value={{
              state: this.state,
              updateValue: this.updateValue
            }}
            >
              <PreSignupForm
                initialReferralCode={referralCode}
                initialReferralCode2={referralCode2}
                referrerData={referrerData}
                referrerData2={referrerData2}
                getReferrerData={getReferrerData}
                submitPartialOnboardingForm={submitForm}
              />
            </PreSignupContext.Provider>
          </Grid>
        
        </Grid>
      );
    } else if (formData.data.score === 'eligible') {
      content = (
        <FormSuccessNew
          referrerData={referrerData}
          clientName={this.state.clientName}
          clientCategory={this.state.status}
        />
      );
    } else {
      content = (
        <FormFailNew/>
      );
    }

    return (
      <React.Fragment>
        <div className="page-content-pre-signup">
          {content}
        </div>
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={this.state.modalOpen}
          onClose={this.handleClose}
        >
          <div className={'political-modal'}>
            <a className={'close'} onClick={this.handleClose}/>
            <Grid
              container
              spacing={0}
              direction="column"
              alignItems="center"
              alignContent={'space-around'}
              justify="center"
            >
              <Grid item xs={12}>
                <img src={ImagePolitical}/>
              </Grid>
              <Grid item xs={12}>
                <ReactMarkdown
                  escapeHtml={false}
                  className={'modal-text'}
                  renderers={{
                    link: (props) => <a href={props.href}>{props.children}</a>
                  }
                  }
                  source={this.props.intl.formatMessage({
                    id: 'applicationForm.modal.political'
                  }, {
                    'email': this.fm('applicationForm.fail.email')
                  })}
                />
              </Grid>
            </Grid>
          </div>
        </Modal>
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={this.state.modalSlovenianOpen}
          onClose={this.handleSlovenianClose}
        >
          <div className={'political-modal'}>
            <a className={'close'} onClick={this.handleSlovenianClose}/>
            <Grid
              container
              spacing={0}
              direction="column"
              alignItems="center"
              alignContent={'space-around'}
              justify="center"
            >
              <Grid item xs={12}>
                <img src={ImageCitizenship}/>
              </Grid>
              <Grid item xs={12}>
                <ReactMarkdown
                  escapeHtml={false}
                  className={'modal-text'}
                  renderers={{
                    link: (props) => <a href={props.href}>{props.children}</a>
                  }
                  }
                  source={this.props.intl.formatMessage({
                    id: 'applicationForm.personalData.isSlovenian.modalContent'
                  }, {
                    'email': this.fm('applicationForm.fail.email')
                  })}
                />
              </Grid>
            </Grid>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  formData: state.boarding.formData,
  referrerData: state.referral.referralCode,
  referrerData2: state.referral.referralCode2,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  getReferrerData: referralActions.getReferrerData,
  submitForm: submitPartialOnboardingForm
}, dispatch);

export default injectIntl(connect(
  mapStateToProps,
  mapDispatchToProps,
)(PreSignupFormPage));
