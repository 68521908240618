import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import WizardPage from './WizardPage';
import { WizardContext } from './WizardContext';
import memoizeOne from 'memoize-one';


export default class Wizard extends React.Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    initialValues: PropTypes.shape({}),
    initialPage: PropTypes.number,
    children: PropTypes.node,
    header: PropTypes.node,
    footer: PropTypes.node,
    className: PropTypes.string
  };
  static Page = WizardPage;

  constructor (props) {
    super(props);
    this.state = {
      page: props.initialPage || 0,
      values: props.initialValues || {},
      completed: []
    };
  }
  next = values =>
    this.setState(state => ({
      completed: state.completed.indexOf(state.page) === -1 ? [...state.completed, state.page] : state.completed,
      page: Math.min(state.page + 1, this.props.children.length - 1),
      values
    }));

  previous = () => this.setState(state => ({
    page: Math.max(state.page - 1, 0)
  }))

  /*
   * NOTE: Both validate and handleSubmit switching are implemented
   * here because 🏁 Redux Final Form does not accept changes to those
   * functions once the form has been defined.
   */
  validate = values => {
    const activePage = React.Children.toArray(this.props.children)[
      this.state.page
    ];
    return activePage.props.validate ? activePage.props.validate(values) : {};
  };

  handleSubmit = values => {
    const { children, onSubmit } = this.props;
    const { page } = this.state;
    const isLastPage = page === React.Children.count(children) - 1;
    if (isLastPage) {
      return onSubmit(values);
    }
    return this.next(values);
  };

  // For memoization to work properly every dependency that can change
  //  should be passed as a parameter
  getWizardContext = memoizeOne((children, activePage, valid, completedPages) => ({
    pages: React.Children.toArray(children).map((child) => child.props.name),
    activePage,
    setActivePage: pageNum => (valid ? this.setState({ page: pageNum }) : null),
    setPreviousPage: this.previous,
    completedPages: completedPages || []
  }))

  render () {
    const {
      children,
      header,
      footer,
      className,
      ...restProps
    } = this.props;
    const { page, values, completed } = this.state;
    const activePage = React.Children.toArray(children)[page];
    return (
      <Form
        {...restProps}
        initialValues={values}
        validate={this.validate}
        onSubmit={this.handleSubmit}
      >
        {({ handleSubmit, form, valid }) => (
          <form onSubmit={handleSubmit} className={className}>
            <WizardContext.Provider
              value={this.getWizardContext(
                children,
                page,
                valid,
                completed
              )}
            >
              {header}
              {Boolean(activePage) && React.cloneElement(activePage, { form })}
              {footer}
            </WizardContext.Provider>
          </form>
        )}
      </Form>
    );
  }
}
