import React from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  FormHelperText,
  NativeSelect,
  InputLabel
} from '@material-ui/core';
import { fieldPropTypes } from '../../types/form';
import './style.scss';

const RadioGroupInput = ({
  input,
  label,
  helperText,
  meta: { touched, error },
  children,
  selectClassName,
  ...rest
}) => (
  <FormControl
    component="fieldset"
    error={touched && Boolean(error)}
    {...rest}
  >
    {label &&
      <InputLabel htmlFor={input.name}>
        {label}
      </InputLabel>
    }
    <NativeSelect
      className={selectClassName}
      {...input}
    >
      {children}
    </NativeSelect>
    <FormHelperText>{(touched && error) || helperText || ' '}</FormHelperText>
  </FormControl>
);

RadioGroupInput.propTypes = {
  ...fieldPropTypes,
  label: PropTypes.node.isRequired,
  selectClassName: PropTypes.string,
  helperText: PropTypes.node
};

export default RadioGroupInput;
