import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';

const ExplanationModal = ({
  title,
  content,
  open,
  onClose
}) => (
  <Dialog
    open={open}
    onClose={onClose}
    className="explanation-modal"
    contentStyle={{ width: '100%',
      maxWidth: 'none' }}
  >
    <div className="explanation-modal__header">
      <IconButton
        onClick={onClose}
        aria-label={'Close dialog'}
      >
        <ClearIcon />
      </IconButton>
    </div>
    <DialogTitle className="explanation-modal__title">{title}</DialogTitle>
    <DialogContent className="explanation-modal__content">
      {content}
    </DialogContent>
  </Dialog>
);

ExplanationModal.propTypes = {
  title: PropTypes.node.isRequired,
  content: PropTypes.node.isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired
};

export default ExplanationModal;
