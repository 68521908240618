import React from 'react';
import { Grid } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

const DataProcessingRules = () => (
  <div className="page-content">
    <Grid container spacing={24}>
      <Grid item xs={12}>
        <FormattedMessage id="dataProcessingRules" />
      </Grid>
    </Grid>
  </div>
);

export default DataProcessingRules;
