import axios from 'axios';
import * as apiCalls from '../../api/boarding';
import ReactGA from 'react-ga';
import uuid from 'uuid/v4';

const { CancelToken } = axios;

export const GET_REFERRER_DATA = 'GET_REFERRER_DATA';
export const GET_REFERRER_DATA_SUCCESS = 'GET_REFERRER_DATA_SUCCESS';
export const GET_REFERRER_DATA_ERROR = 'GET_REFERRER_DATA_ERROR';

const lastTokenSources = {};

export const getReferrerData = (referralCode, codeType) => async (dispatch) => {
  try {
    const tokenSource = CancelToken.source();

    if (lastTokenSources[codeType]) {
      lastTokenSources[codeType].cancel('Operation cancelled by subsequent call');
    }

    lastTokenSources[codeType] = tokenSource;

    dispatch({
      type: GET_REFERRER_DATA,
      referralCode,
      codeType
    });

    const response = await apiCalls.getReferrerData(referralCode, tokenSource.token);

    if (response && response.data.isBranch) {
      const userId = uuid();
      if (localStorage) {
        localStorage.setItem('userId', userId);
      }
      ReactGA.set({ userId });
    }

    return dispatch({
      type: GET_REFERRER_DATA_SUCCESS,
      referralCode,
      codeType,
      data: response.data
    });
  } catch (e) {
    if (!axios.isCancel(e)) {
      return dispatch({
        type: GET_REFERRER_DATA_ERROR,
        referralCode,
        codeType,
        error: e.message
      });
    }

    return null;
  }
};
