import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Paper } from '@material-ui/core';
import * as actions from '../../../../redux/actions/fuelSavingsActions';
import FuelSavingsForm from '../FuelSavingsForm/index';
import { fuelSavings as fuelSavingsType } from '../../../types/fuelSavings';

export class FuelSavingsPage extends React.Component {
  saveFuelSavings = () => {
    this.props.actions.saveFuelSavings(this.props.fuelSavings);
  }

  calculateFuelSavings = e => {
    this.props.actions.calculateFuelSavings(this.props.fuelSavings, e.target.name, e.target.value);
  }

  render () {
    return (
      <Paper className="content">
        <FuelSavingsForm
          onSaveClick={this.saveFuelSavings}
          onChange={this.calculateFuelSavings}
          fuelSavings={this.props.fuelSavings}
        />
      </Paper>
    );
  }
}

FuelSavingsPage.propTypes = {
  actions: PropTypes.shape({
    saveFuelSavings: PropTypes.func.isRequired,
    calculateFuelSavings: PropTypes.func.isRequired
  }).isRequired,
  fuelSavings: fuelSavingsType.isRequired
};

const mapStateToProps = (state) => ({
  fuelSavings: state.fuelSavings
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FuelSavingsPage);
