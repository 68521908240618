import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

const HeaderCardContent = ({ className, ...restProps }) => (
  <div {...restProps} className={classNames('header-card__content', className)} />
);

HeaderCardContent.propTypes = {
  className: string
};

export default HeaderCardContent;
