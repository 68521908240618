import React from 'react';
import ReactMarkdown from 'react-markdown';
import {Grid, Button} from '@material-ui/core';
import {FormattedMessage, injectIntl, intlShape} from 'react-intl';
import '../CardApplication/CardApplicationForm/style.scss';
import './style.scss';
import ReactGA from 'react-ga';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import ImageNo from '../../../resources/images/NO.svg';

const helpUrl = 'mailto:dmobile@erstecard.si';

class FormFailNew extends React.Component {
  static propTypes = {
    intl: intlShape.isRequired,
  };

  componentDidMount() {
    ReactGA.event({
      category: 'JADE',
      action: 'Request',
      label: 'Preliminary NO'
    });
  }

  fm(message) {
    return this.props.intl.formatMessage({id: message});
  }

  render() {
    const {intl} = this.props;

    return (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        alignContent={'space-around'}
        justify="center"
        style={{minHeight: '100vh'}}
      >
        <Grid item xs={3}>
          <div className={'form-fail-polygon'}>
            <img src={ImageNo} className={'fail-icon'}/>
            <div className={'fail-content'}>
              <Grid container spacing={24}>
                <Grid item xs={12}>
                  <h1 className={'title'}>
                    <FormattedMessage id={'applicationForm.fail.title'}/>
                  </h1>
                </Grid>
                <Grid item xs={12}>
                  <ReactMarkdown
                    escapeHtml={false}
                    className={'body'}
                    renderers={{
                      link: (props) => {
                        return <a href={props.href}>{props.children}</a>;
                      }
                    }
                    }
                    source={this.props.intl.formatMessage({
                      id: 'applicationForm.fail.subtitle'
                    }, {
                      'email': this.fm('applicationForm.fail.email')
                    })}
                  />
                </Grid>
                <Grid item xs={12}>
                  <a className={'link'} href={helpUrl}>
                    <div>
                      <MailOutlineIcon/>
                      <FormattedMessage id={'applicationForm.fail.help'}/>
                    </div>
                  </a>
                </Grid>
              </Grid>
            </div>
          </div>
        </Grid>
      </Grid>

    );
  }
}

export default injectIntl(FormFailNew);
