import React from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  InputAdornment,
  Select,
  MenuItem,
  NativeSelect
} from '@material-ui/core';
import classNames from 'classnames';
import {fieldPropTypes} from '../../types/form';
import './style.scss';
import { isMobile } from "react-device-detect";

const onChange = (input, originalOnChange, validationProps) => (e) => {
  originalOnChange(e.target.value);
};

const HTMLOption = (props) => <option {...props}>{props.children}</option>;

const BasicSelectInput = ({
                     input,
                     label,
                     placeholder,
                     helperText,
                     meta: {touched, error},
                     inputProps,
                     validationProps,
                     className,
                     tooltip,
                     options,
                     itemClassName,
                     prefixText,
                     ...custom
                   }) => {

  const SelectComponent = isMobile ? NativeSelect : Select;
  const SelectComponentItem = isMobile ? HTMLOption : MenuItem;

  const selectComponent = <SelectComponent
    className={classNames("form-control__input", { 'with-type': inputProps && inputProps.type })}
    {...input}
    autoComplete={'off'}
    placeholder={placeholder}
    disableUnderline
    onChange={onChange(input, input.onChange, validationProps)}
    {...inputProps}
  >
    {isMobile && <SelectComponentItem className={itemClassName} key={'empty'} value={''}>{''}</SelectComponentItem> }
    { options.map((option) => (
      <SelectComponentItem className={itemClassName} key={option.value} value={option.value}>{option.label || option.value.toString()}</SelectComponentItem>
    )) }
  </SelectComponent>;

  return (
    <FormControl
      error={touched && Boolean(error)}
      {...custom}
      className={classNames('form-control', className)}
    >
      <InputLabel className="form-control__label" shrink htmlFor={custom.id}>{label}</InputLabel>
      { prefixText && <div className="with-prefix"><span className="prefix">{prefixText}</span>{ selectComponent }</div> }
      { !prefixText && selectComponent }
      <FormHelperText className="form-control__helper">{(touched && error) || helperText || ' '}</FormHelperText>
    </FormControl>
  );
};

BasicSelectInput.propTypes = {
  ...fieldPropTypes,
  className: PropTypes.string,
  label: PropTypes.node.isRequired,
  inputProps: PropTypes.shape({}),
  validationProps: PropTypes.shape({}),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.string
    })
  ),
  itemClassName: PropTypes.string,
  helperText: PropTypes.node,
  tooltip: PropTypes.node,
  placeholder: PropTypes.node,
  prefixText: PropTypes.string
};

export default BasicSelectInput;
