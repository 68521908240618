import { shape, string, bool, any, func, node, oneOfType } from 'prop-types';

export const formPropTypes = {
  error: node,
  touched: bool
};

export const fieldPropTypes = {
  input: shape({
    onChange: func.isRequired,
    onBlur: func.isRequired,
    value: any,
    name: string.isRequired
  }).isRequired,
  meta: shape(formPropTypes)
};

export const referrerDataPropTypes = shape({
  code: string,
  data: shape({
    success: bool.isRequired,
    isStudent: bool,
    isBranch: bool,
    isMerchant: bool
  }),
  error: oneOfType([string, shape({})])
});
