/* eslint-disable import/no-named-as-default */
import { Route, Switch } from 'react-router-dom';
import React from 'react';
import { hot } from 'react-hot-loader';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import queryString from 'query-string';
import ReactGA from 'react-ga';
import { injectIntl, intlShape } from 'react-intl';
import PreSignupFormPage from './pages/PreSignupForm/PreSignupFormPage';
import CardApplicationPage from './pages/CardApplication/CardApplicationPage';
import FuelSavingsPage from './pages/FuelSavings/FuelSavingsPage';
import TermsAndConditions from './pages/ContentPages/TermsAndConditions';
import DataProcessingRules from './pages/ContentPages/DataProcessingRules';
import NotFoundPage from './pages/NotFoundPage';
import Config from '../../config';
import { Grid } from '@material-ui/core';
import { addToastInterceptor, removeToastInterceptor } from '../api/toastInterceptor';
import ImageLogo from '../resources/images/Erste Card_Diners Club_logo_2019.png';

/* eslint-disable camelcase */
const { utm_source, utm_medium, utm_campaign, utm_content, utm_keyword } = queryString.parse(location.search);
ReactGA.initialize(Config.GA_TRACKING_ID, {
  testMode: Config.GA_TESTING,
  debug: true,
  gaOptions: {
    campaignName: utm_campaign,
    campaignSource: utm_source,
    campaignMedium: utm_medium,
    campaignContent: utm_content,
    campaignKeyword: utm_keyword
  }
});

/* eslint-enable camelcase */

// This is a class-based component because the current
// version of hot reloading won't hot reload a stateless
// component at the top-level.

let interceptor = null;

class App extends React.Component {
  static propTypes = {
    intl: intlShape.isRequired
  };

  constructor (props) {
    super(props);
    if (!interceptor) {
      interceptor = addToastInterceptor((e) =>
      {
        if (e && e.response && e.response.data && Array.isArray(e.response.data.errors) && e.response.data.errors.length > 0 && e.response.data.errors[0].message) {
          return e.response.data.errors[0].message;
        } else {
          return props.intl.formatMessage({
            id: 'applicationForm.basic.errorMessage'
          });
        }

          // props.intl.formatMessage({
          //   id: 'applicationForm.basic.errorMessage'
          // }, {
          //   technicalMessage: e.message
          // })
      });
    }
  }

  componentWillUnmount () {
    removeToastInterceptor(interceptor);
    interceptor = null;
  }

  render () {
    return (
      <React.Fragment>
        <Grid className="headerGrid">
          <Grid item xs={12} sm={6} className="headerGridItem1">
            <a href="https://www.dinersclub.si/">
              <img src={ImageLogo} className="headerLogo" />
            </a>
          </Grid>
        </Grid>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />
        {/*<TopMenu />*/}
        <Switch>
          <Route
            exact
            path="/"
            component={PreSignupFormPage}
          />
          <Route
            exact
            path="/old-form"
            component={CardApplicationPage}
          />
          <Route
            path="/fuel-savings"
            component={FuelSavingsPage}
          />
          <Route
            path="/terms-and-conditions"
            component={TermsAndConditions}
          />
          <Route
            path="/data-processing-rules"
            component={DataProcessingRules}
          />
          <Route component={NotFoundPage} />
        </Switch>
      </React.Fragment>
    );
  }
}

export default hot(module)(injectIntl(App));
