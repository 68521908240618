import React, { Fragment } from 'react';
import { Grid, Button, CircularProgress } from '@material-ui/core';
import {FormattedMessage} from 'react-intl';
import PropTypes from 'prop-types';
import PreSignupContext from '../PreSignupForm/PreSignpupContext';
import ModalForm from '../PreSignupForm/FormParts/ModalForm';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import { Form, FormSpy } from 'react-final-form';
import Modal from '@material-ui/core/Modal';
import { injectIntl, intlShape } from 'react-intl';
import * as referralActions from '../../../redux/actions/referralActions';
import queryString from 'query-string';
import { referrerDataPropTypes } from '../../types/form';
import ImageSidebarDefault from '../../../resources/images/sidebar_default.svg';
import ImageCalculator from '../../../resources/images/Illustration 1 - 01 Onboarding ver3.svg';
import ImageCouple from '../../../resources/images/Couple.svg';
import ImageClose from '../../../resources/images/button-close.svg';
import ImageSidebarMoney from '../../../resources/images/sidebar_money.svg';
import ImageSidebarPolitical from '../../../resources/images/sidebar_political.svg';
import  calculatorStore  from '../../stores/calculatorStore';
import  ApprovedAmount  from '../../components/ApprovedAmount/ApprovedAmount';
import { observer } from "mobx";
import Dialog, {
  DialogTitle,
  DialogContent,
  DialogFooter,
  DialogButton,
} from '@material/react-dialog';
import RejectedAmount from '../../components/RejectedAmmount/RejectedAmmount';

 const helpUrl = 'mailto:dmobile@erstecard.si';



class PreSignupSideInfo extends React.Component {


  constructor (props) {
    super(props);
    this.state = {

      modalOpen: false,
      isValid: false,
      isButtonColorChange: false,
      isRejected: false,
      amountNumber: 0.00

    };
    this.handleOpen = this.handleOpen.bind(this);
    this.handleData = this.handleData.bind(this);
  }



  componentDidMount = () => {
    console.log('calculatorStore.isClicked',calculatorStore.isClicked);
    if(calculatorStore.isClicked === false) {
      this.setState({ modalOpen: false });
    } }

   handleOpen = () => {
    this.setState({ modalOpen: true });
    this.setState({ isButtonColorChange: false });
    calculatorStore.isClicked = true;
    calculatorStore.isImageCalculator = true;
    calculatorStore.isSideCouple = false;
    calculatorStore.isSideMoney = false;
    console.log('Modal:', calculatorStore.isClicked);
  }

  handleClose = () => {
      this.setState({ modalOpen: false });
  }

  handleData = (data) => {
    if(data.body !== " "){
      this.setState({ isButtonColorChange: true });
      this.setState({ isValid: true });
      this.setState({ isRejected: false });
      this.setState({ amountNumber: data.body });
    }else{
      this.setState({ isButtonColorChange: true });
      this.setState({ isValid: data.isValid });
      this.setState({ isRejected: true });
    }
}

  render () {

    const {
      submitForm
    } = this.props;
    let sideImage;
    // debugger
    // console.log("isImageCalculator :", calculatorStore.isImageCalculator)
    // console.log("isSideCouple :", calculatorStore.isSideCouple)
    // console.log("isSideMoney :", calculatorStore.isSideMoney)
    // console.log("isSidePolitical :", calculatorStore.isSidePolitical)
    if(calculatorStore.isImageCalculator){
      sideImage =  ImageCalculator
    }else if(calculatorStore.isSideCouple){
      sideImage =  ImageCouple
    }else if(calculatorStore.isSideMoney){
      sideImage =  ImageSidebarMoney
    }else if(calculatorStore.isSidePolitical){
      sideImage =  ImageSidebarPolitical
    }

    let initialText = calculatorStore.isImageCalculator;

    return (
      <React.Fragment>
         <PreSignupContext.Consumer>

       {(context) => (
         <React.Fragment>
         <div className={'sideinfo'}>
              <Grid container spacing={24} alignItems={'center'}>
                <Grid item xs={12}>
                  <div className={'sideinfo-title'}>
                    <FormattedMessage id="applicationForm.sideinfo.title" />
                    <div className={'body'}>
                      <FormattedMessage id="applicationForm.sideinfo.title.text" />
                      {this.state.isValid &&
                        <ApprovedAmount
                        amount={this.state.amountNumber}
                        />
                    }
                      {this.state.isRejected &&
                        <RejectedAmount
                          amount={this.state.amountNumber}
                        />
                      }

                    <Button
                      onClick={this.handleOpen}
                      className={this.state.isButtonColorChange ? "calculator-modal-button-finished":"calculator-modal-button"}
                      color="secondary"
                      variant="raised"

                    >
                      {/* <FormattedMessage id={this.state.isValid ? "applicationForm.calculator.after.submit" : "applicationForm.calculator.before.submit"} /> */}
                      <FormattedMessage id={"applicationForm.calculator.before.submit"} />
                      {/* {submitting && (
      <CircularProgress className="progress" size={30}/>
    )} */}
                      <i className="modal-arrow" />
                    </Button>


                    </div>

                 <a className={'link'} href={helpUrl}>
                      <div>
                        <FormattedMessage id="applicationForm.sideinfo.title.link" />
                      </div>
                    </a>
                  </div>

                </Grid>
                <Grid item xs={12}>
                  <img src={sideImage} className="presignup-form__image image-responisve" />
                </Grid>
                <Grid item xs={12}>
                  <div className={'sideinfo-status'}>
                    {initialText ? "" : <FormattedMessage id={`applicationForm.sideinfo.status.title.${context.state.employeeType}`} /> }
                    {initialText ? "" : <FormattedMessage id={`applicationForm.sideinfo.status.title.${context.state.employeeType}.subtext`} /> }
                    <div className={'body'}>
                      {initialText ? "" : <FormattedMessage id={`applicationForm.sideinfo.status.body.${context.state.employeeType}`} />}
                    </div>
                  </div>

                </Grid>
              </Grid>
            </div>
            <Modal
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
              open={this.state.modalOpen}
              onClose={this.handleClose}
            >
                <div className={'calculator-modal'}>
                  <div className="closeDiv">

                    <a className="closeModal" onClick={this.handleClose}>
                      <img src={ImageClose} alt=""/>
                    </a>
                  </div>

                  <ModalForm
                  handleData={this.handleData}
                  closeModal={this.handleClose}
                submitPartialOnboardingForm={submitForm}
              />
                </div>
              </Modal>
              </React.Fragment>
       )}

     </PreSignupContext.Consumer>
      </React.Fragment>
     );
  }
}

PreSignupSideInfo.propTypes = {
  content: PropTypes.any,
  status: PropTypes.any
}



export default PreSignupSideInfo
