import React from 'react';

export const WizardContext = React.createContext({
  pages: [],
  activePage: 0,
  setActivePage: () => {
    //noop
  },
  setPreviousPage: () => {
    //noop
  },
  completedPages: []
});

const getDisplayName = Component => {
  const displayName = Component.displayName || Component.name || 'Component';

  return `WizardContext(${displayName})`;
};

// Export a higher order component for consuming the wizard context
// This is just to abstract away the subscription logic and make the context
// available directly in props
export const withWizardContext = Component => class extends React.Component {
  static displayName = getDisplayName(Component)

  render () {
    // eslint-disable-next-line react/no-children-prop
    return React.createElement(WizardContext.Consumer, {
      children: wizardContext =>
        React.createElement(Component, {
          wizardContext,
          ...this.props
        })
    });
  }
};
