import React from 'react';

import { node } from 'prop-types';

const WizardPage = ({ children, ...restProps }) => React.Children.map(children, (child) => (
  React.cloneElement(child, {
    ...restProps
  })
));

WizardPage.propTypes = {
  children: node.isRequired,
  name: node.isRequired
};

export default WizardPage;
