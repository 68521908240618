import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Field } from 'react-final-form';
import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import FormLabel from '@material-ui/core/FormLabel';
import FileUpload from '../../../../components/FileUpload/index';

const FileUploadFormPart = () => (
  <React.Fragment>
    <Grid container spacing={24}>
      <Grid item xs={12}>
        <Typography
          className="subheading"
          variant="subheading"
        >
          4. <FormattedMessage id="applicationForm.documentUpload.title"/>
        </Typography>
      </Grid>
˚
      <Grid item xs={12}>
        <FormLabel>
          <FormattedMessage id="applicationForm.documentUpload.idCard"/>
        </FormLabel>
      </Grid>
      <Grid item xs={12}>
        <div className="block">
          <Field
            name="idCardImages"
          >
            {({ input }) => (
              <FileUpload
                {...input}
              />
            )}
          </Field>
        </div>
      </Grid>

      <Grid item xs={12}>
        <FormLabel>
          <FormattedMessage id="applicationForm.documentUpload.payslip"/>
        </FormLabel>
      </Grid>
      <Grid item xs={12}>
        <div className="block">
          <Field name="payslipImages">
            {({ input }) => (
              <FileUpload
                {...input}
              />
            )}
          </Field>
        </div>
      </Grid>
    </Grid>
  </React.Fragment>
);

export default FileUploadFormPart;
