import { SAVE_FUEL_SAVINGS, CALCULATE_FUEL_SAVINGS } from '../actions/fuelSavingsActions';
import { necessaryDataIsProvidedToCalculateSavings, calculateSavings } from '../../utils/fuelSavings';
import initialState from './initialState';

export default (state = initialState.fuelSavings, action) => { // NOSONAR
  switch (action.type) {
    case SAVE_FUEL_SAVINGS:
      // For this example, just simulating a save by changing date modified.
      // In a real app using Redux, you might use redux-thunk and handle the async call in fuelSavingsActions.js
      return {
        ...state,
        dateModified: action.dateModified
      };

    case CALCULATE_FUEL_SAVINGS: {
      let newState = {
        ...state,
        [action.fieldName]: action.value
      };

      newState = {
        ...newState,
        necessaryDataIsProvidedToCalculateSavings: necessaryDataIsProvidedToCalculateSavings(newState),
        dateModified: action.dateModified
      };

      if (newState.necessaryDataIsProvidedToCalculateSavings) {
        newState = {
          ...newState,
          savings: calculateSavings(newState)
        };
      }

      return newState;
    }
    default:
      return state;
  }
};
