import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { IntlProvider, addLocaleData } from 'react-intl';
import en from 'react-intl/locale-data/en';
import hr from 'react-intl/locale-data/hr';
import sl from 'react-intl/locale-data/sl';
import locale from '../resources/locales/locale.sl.json';
import moment from 'moment';
import App from './App';
import config from '../../config';

moment.locale('sl');
addLocaleData([...en, ...hr, ...sl]);

export default class Root extends Component {
  render () {
    const { store } = this.props;
    return (
      <Provider store={store}>
        <IntlProvider
          locale="sl"
          messages={locale}
        >
          <BrowserRouter basename={config.BASE_URL}>
            <App />
          </BrowserRouter>
        </IntlProvider>
      </Provider>
    );
  }
}

Root.propTypes = {
  store: PropTypes.shape({
    subscribe: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    getState: PropTypes.func.isRequired
  }).isRequired
};
