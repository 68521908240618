import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import _ from 'lodash';
import FileUploadPreview from './FileUploadPreview';
import { Button, GridList } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import './FileUpload.scss';


class FileUpload extends React.Component {
  static propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.arrayOf(PropTypes.shape({}).isRequired)
  };

  constructor (props) {
    super(props);

    this.onDrop.bind(this);
  }

  onDrop (files) {
    const { onChange } = this.props;
    let { value } = this.props;

    if (!_.isArray(value)) {
      value = [];
    }

    files.forEach((file, index) => {
      file.id = new Date().getTime() + index;
      file.preview = window.URL.createObjectURL(file);
    });

    const newFileList = [...value, ...files];
    if (onChange) {
      onChange([...newFileList]);
    }
  }

  onRemove (fileToRemove) {
    const { onChange, value } = this.props;

    window.URL.revokeObjectURL(fileToRemove.preview);
    const newFileList = value.filter((file) => file !== fileToRemove);
    if (onChange) {
      onChange([...newFileList]);
    }
  }

  render () {
    let dropzoneRef = null;
    let { value } = this.props;
    if (!_.isArray(value)) {
      value = [];
    }
    return (
      <Dropzone
        onDrop={(file) => this.onDrop(file)}
        disableClick
        ref={(node) => {
          dropzoneRef = node;
        }}
        className="file-upload__dropzone"
        style={{}}
      >
        <Button color="primary" onClick={() => dropzoneRef.open()}>
          <FormattedMessage id="fileupload.addFiles" />
        </Button>
        <GridList
          cellHeight={180}
          spacing={4}
          className="file-upload"
        >
          {value.map((file) => (
            <FileUploadPreview
              key={file.id}
              file={file}
              onRemove={() => this.onRemove(file)}
            />
          ))}
        </GridList>
      </Dropzone>
    );
  }
}


export default FileUpload;
