import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Field } from 'react-final-form';
import TextInput from '../../../../components/inputs/TextInput';
import {
  composeValidators,
  required,
  noSpecialCharacters,
  email
} from '../../../../../utils/validators/translated';
import Grid from '@material-ui/core/Grid';

const AddressFormPart = ({ name, addressType }) => {
  if (addressType === 'residence' || addressType === 'employer' || addressType === 'different') {
    return (
      <React.Fragment>
        <Grid container spacing={24}>
          <Grid item xs={12} sm={6}>
            <Field
              component={TextInput}
              className="text-field"
              name={`${name}.address`}
              label={<FormattedMessage id="applicationForm.personalData.address" />}
              validate={composeValidators(required, noSpecialCharacters)}
              validateFields={[]}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              component={TextInput}
              className="text-field"
              name={`${name}.houseNumber`}
              label={<FormattedMessage id="applicationForm.personalData.houseNumber" />}
              validate={composeValidators(required, noSpecialCharacters)}
              validateFields={[]}
              required
            />
          </Grid>
        </Grid>
        <Grid container spacing={24}>
          <Grid item xs={12} sm={6}>
            <Field
              component={TextInput}
              className="text-field"
              name={`${name}.zipCode`}
              label={<FormattedMessage id="applicationForm.personalData.zipCode" />}
              validate={composeValidators(required, noSpecialCharacters)}
              validateFields={[]}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              component={TextInput}
              className="text-field"
              name={`${name}.city`}
              label={<FormattedMessage id="applicationForm.personalData.city" />}
              validate={composeValidators(required, noSpecialCharacters)}
              validateFields={[]}
              required
            />
          </Grid>
        </Grid>
      </React.Fragment>
    );
  } else if (addressType === 'eBill') {
    return (
      <Grid container spacing={24}>
        <Grid item xs={12}>
          <Field
            component={TextInput}
            className="text-field"
            name={`${name}.email`}
            label={<FormattedMessage id="applicationForm.personalData.email" />}
            validate={composeValidators(required, email)}
            validateFields={[]}
            required
          />
        </Grid>
      </Grid>
    );
  }

  return null;
};

AddressFormPart.propTypes = {
  name: PropTypes.string.isRequired,
  addressType: PropTypes.string
};

export default AddressFormPart;
