import axios, { AxiosError, AxiosResponse } from "axios";
import config from '../../config';

axios.defaults.baseURL = `${config.API_BASE_URL}/`;

const responseBody = function (response) {
  return response.data;
};

const requests = {
  get: function (url) {
    return axios.get(url).then(responseBody);
  },
  post: function (url, body) {
    return axios.post(url, body).then(responseBody);
  },
  put: function (url, body) {
    return axios.put(url, body).then(responseBody);
  },
  del: function (url) {
    return axios.delete(url).then(responseBody);
  },
};

  const Calculator = {
    calculate: (formData) => requests.post(`${config.API_BASE_URL}/calculator`, formData)
  };


const agent = {
  Calculator,
}

export default agent;
