// eslint-disable-next-line no-process-env
let environment = process.env.ENV;

if (!environment) {
  environment = 'local';
}

const env = require(`./${environment}.js`).default;

export default env;
