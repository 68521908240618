import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import Media from 'react-media';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import AddressInformationFormPart from './FormParts/AddressInformationFormPart';
import PersonalDataFormPart from './FormParts/PersonalDataFormPart';
import ContactInformationFormPart from './FormParts/ContactInformationFormPart';
import FileUploadFormPart from './FormParts/FileUploadFormPart';
import IncomeDataFormPart from './FormParts/IncomeDataFormPart';
import Wizard from '../../../components/Wizard';
import WizardNavigation from '../../../components/Wizard/WizardNavigation';
import WizardStepper from '../../../components/Wizard/WizardStepper';
import * as actions from '../../../../redux/actions/boardingActions';
import './style.scss';

class CardApplicationForm extends React.Component {
  render () {
    const {
      submitOnboardingForm
    } = this.props;

    return (
      <Wizard
        onSubmit={submitOnboardingForm}
        initialValues={{
          dateOfBirth: moment(Date.now()).subtract(30, 'years'),
          citizenship: 191
        }}
        subscription={{ valid: true }}
        header={
          <React.Fragment>
            <Typography variant="headline" gutterBottom>
              <FormattedMessage id="applicationForm.title" />
            </Typography>
            <Media query="(min-width: 650px)">
              <WizardStepper />
            </Media>
          </React.Fragment>
        }
        footer={
          <WizardNavigation
            previousLabel={<FormattedMessage id="applicationForm.previous" />}
            nextLabel={<FormattedMessage id="applicationForm.next" />}
            submitLabel={<FormattedMessage id="applicationForm.submit" />}
          />
        }
        className="card-application-form"
      >
        <Wizard.Page name={<FormattedMessage id="applicationForm.personalData.title" />}>
          <PersonalDataFormPart />
        </Wizard.Page>
        <Wizard.Page name={<FormattedMessage id="applicationForm.addressInformation.title" />}>
          <AddressInformationFormPart />
        </Wizard.Page>
        <Wizard.Page name={<FormattedMessage id="applicationForm.contactInformation.title" />}>
          <ContactInformationFormPart />
        </Wizard.Page>
        <Wizard.Page name={<FormattedMessage id="applicationForm.documentUpload.title" />}>
          <FileUploadFormPart />
        </Wizard.Page>
        <Wizard.Page name={<FormattedMessage id="applicationForm.incomeData.title" />}>
          <IncomeDataFormPart />
        </Wizard.Page>
      </Wizard>
    );
  }
}

CardApplicationForm.propTypes = {
  submitOnboardingForm: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => bindActionCreators({
  submitOnboardingForm: actions.submitOnboardingForm
}, dispatch);

export default connect(
  null,
  mapDispatchToProps,
)(CardApplicationForm);
