import React from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  InputAdornment
} from '@material-ui/core';
import classNames from 'classnames';
import {fieldPropTypes} from '../../types/form';
import './style.scss';
import calculatorStore from '../../stores/calculatorStore';

const onChange = (input, context, originalOnChange, validationProps) => (e) => {
  if (input.name === 'firstName') {
    context.updateValue('clientName', e.target.value);
  }
  if (input.name === 'vatNumber') {
    let targetValue = e.target.value
    if (targetValue.length == 0 || (targetValue.length <= 8 && /^\d+$/.test(targetValue))) {
      originalOnChange(targetValue)
    }
  } else {
    originalOnChange(e.target.value);
  }
};
const TextInput = ({
                     input,
                     label,
                     placeholder,
                     helperText,
                     meta: {touched, error},
                     inputProps,
                     validationProps,
                     className,
                     tooltip,
                     context,
                     prefixText,
                     ...custom
                   }) => (
  <FormControl
    error={touched && Boolean(error)}
    {...custom}
    className={classNames('form-control', className)}
  >
    <InputLabel className="form-control__label" shrink htmlFor={custom.id}>{label}</InputLabel>
    <Input
      startAdornment={prefixText && <InputAdornment className="prefix" position="start">{prefixText}</InputAdornment>}
      className={classNames("form-control__input", { 'with-type': inputProps && inputProps.type })}
      {...input}
      autoComplete={'off'}
      onFocus={() => {
        if (calculatorStore) {
          calculatorStore.isImageCalculator = false;
          calculatorStore.isSideCouple = true;
        }
      }}
      onBlur={(e) => {
        if (calculatorStore) {
          calculatorStore.isImageCalculator = !calculatorStore.isImageCalculator;
          calculatorStore.isSideCouple = !calculatorStore.isSideCouple;
        }
        input.onBlur(e.target.value);
      }}
      placeholder={placeholder}
      disableUnderline
      onChange={onChange(input, context, input.onChange, validationProps)}
      {...inputProps}

    />
    <FormHelperText className="form-control__helper">{(touched && error) || helperText || ' '}</FormHelperText>
  </FormControl>
);

TextInput.propTypes = {
  ...fieldPropTypes,
  className: PropTypes.string,
  prefixText: PropTypes.string,
  label: PropTypes.node.isRequired,
  inputProps: PropTypes.shape({}),
  validationProps: PropTypes.shape({}),
  helperText: PropTypes.node,
  tooltip: PropTypes.node,
  placeholder: PropTypes.node,
  context: PropTypes.shape({})
};

export default TextInput;
