import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Field } from 'react-final-form';
import {
  FormControlLabel,
  Radio,
  Typography
} from '@material-ui/core';
import AddressFormPart from './AddressFormPart';
import RadioGroupInput from '../../../../components/inputs/RadioGroupInput';
import {
  required,
} from '../../../../../utils/validators/translated';
import Grid from '@material-ui/core/Grid';

const ContactInformationFormPart = () => (
  <React.Fragment>
    <Grid container spacing={24}>
      <Grid item xs={12}>
        <Typography
          variant="subheading"
          className="subheading"
        >
          3. <FormattedMessage id="applicationForm.contactInformation.title" />
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Field
          name="deliveryAddressType"
          className="form-control"
          groupClassName="radio-group"
          component={RadioGroupInput}
          validate={required}
          validateFields={[]}
          required
          label={<FormattedMessage id="applicationForm.personalData.deliveryAddressType" />}
        >
          <FormControlLabel
            value="permanent"
            control={<Radio />}
            label={<FormattedMessage id="applicationForm.personalData.addressType.permanentAddress" />}
          />
          <FormControlLabel
            value="different"
            control={<Radio />}
            label={<FormattedMessage id="applicationForm.personalData.addressType.differentAddress" />}
          />
        </Field>
      </Grid>
    </Grid>
    <Grid container spacing={24}>
      <Grid item xs={12}>
        <Field name="deliveryAddressType" subscription={{ value: true }}>
          {({ input: deliveryAddressTypeInput }) => (
            <AddressFormPart
              addressType={deliveryAddressTypeInput.value}
              name="deliveryAddress"
              label={<FormattedMessage id="applicationForm.personalData.deliveryAddress" />}
            />
          )}
        </Field>
      </Grid>
      <Grid item xs={12}>
        <Field
          name="invoiceAddressType"
          className="form-control"
          groupClassName="radio-group"
          component={RadioGroupInput}
          validate={required}
          validateFields={[]}
          required
          label={<FormattedMessage id="applicationForm.personalData.invoiceAddressType" />}
        >
          <FormControlLabel
            value="permanent"
            control={<Radio />}
            label={<FormattedMessage id="applicationForm.personalData.addressType.permanentAddress" />}
          />
          <FormControlLabel
            value="different"
            control={<Radio />}
            label={<FormattedMessage id="applicationForm.personalData.addressType.differentAddress" />}
          />
        </Field>
      </Grid>
      <Grid item xs={12}>
        <Field name="invoiceAddressType" subscription={{ value: true }}>
          {({ input: invoiceAddressTypeInput }) => (
            <AddressFormPart
              addressType={invoiceAddressTypeInput.value}
              name="invoiceAddress"
              label={<FormattedMessage id="applicationForm.personalData.invoiceAddress" />}
            />
          )}
        </Field>
      </Grid>
    </Grid>
  </React.Fragment>
);

export default ContactInformationFormPart;
