import {
  BOARDING_FINISH_FORM,
  BOARDING_GET_COUNTRIES_FINISHED
} from '../actions/boardingActions';

const initialState = {
  formData: null,
  countries: []
};

export default (state = initialState, action) => { // NOSONAR
  switch (action.type) {
    case BOARDING_FINISH_FORM:
      return {
        ...state,
        formData: action.data
      };

    case BOARDING_GET_COUNTRIES_FINISHED:
      return {
        ...state,
        countries: action.data
      };

    default:
      return state;
  }
};
