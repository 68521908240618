import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import './RejectedAmount.scss';
import myData from '../../../resources/locales/locale.sl.json';




class RejectedAmount extends React.Component {
  static propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.arrayOf(PropTypes.shape({}).isRequired)
  };

  constructor (props) {
    super(props);
  }



  render () {
    let { value,amount } = this.props;
    return (

      <div className="mainContiner">
        <div className="amountTextRejected">
          <div dangerouslySetInnerHTML={{__html: myData['applicationForm.amount.rejected.text']}} ></div>

        </div>
        <div className="amountInfo">
          <FormattedMessage id="applicationForm.amount.approved.info" />
        </div>
      </div>

    );
  }
}


export default RejectedAmount;
