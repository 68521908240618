import React from 'react';
import PropTypes from 'prop-types';
import {
  FormControlLabel,
  Radio
} from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import './style.scss';
import ImageCheck from '../../../resources/images/Check.svg';

const StatusRadio = ({
  value,
  label,
  className,
  ...custom
}) => (
  <FormControlLabel
    value={value}
    control={<Radio
      checkedIcon={<img src={ImageCheck} className="form-control__square-radio--checked" />}
      className="form-control__status-radio"
      color="default"
      icon={<div/>}
    />}
    label={label}
    className={className}
    {...custom}
  />
);

StatusRadio.propTypes = {
  label: PropTypes.node.isRequired,
  value: PropTypes.string.isRequired,
  className: PropTypes.string
};

export default StatusRadio;
