import React from 'react';
import PropTypes from 'prop-types';
import { FormSpy } from 'react-final-form';
import { Button, CircularProgress } from '@material-ui/core';
import Media from 'react-media';
import WizardMobileStepper from './WizardMobileStepper';
import Grid from '@material-ui/core/Grid';
import { withWizardContext } from './WizardContext';


const WizardNavigation = ({
  wizardContext: {
    pages,
    activePage,
    setPreviousPage,
  },
  previousLabel,
  nextLabel,
  submitLabel
}) => {
  const isLastPage = activePage === pages.length - 1;
  const pageCount = pages.length;

  const mobileStepper = (
    <WizardMobileStepper
      steps={pageCount}
      activeStep={activePage}
      onBack={setPreviousPage}
    />
  );

  const desktopButtons = (
    <Grid container spacing={24}>

      {activePage > 0 && (
        <Grid item xs={6}>
          <Button
            className="text-field"
            type="button"
            onClick={setPreviousPage}
            color="primary"
            variant="raised"
          >
            {previousLabel}
          </Button>
        </Grid>
      )}

      {!isLastPage && (
        <Grid item xs={6}>
          <Button
            className="text-field"
            type="submit"
            color="primary"
            variant="raised"
          >
            {nextLabel}
          </Button>
        </Grid>
      )}
      {isLastPage && (
        <Grid item xs={6}>
          <FormSpy
            subscription={{
              submitting: true
            }}
          >
            {({ submitting }) => (
              <Button
                className="text-field"
                type="submit"
                color="primary"
                variant="raised"
                disabled={submitting}
              >
                {submitLabel}
                {submitting && (
                  <CircularProgress className="progress" size={20} />
                )}
              </Button>
            )}
          </FormSpy>
        </Grid>
      )}
    </Grid>
  );

  return (
    <div className="block">
      <Media query="(max-width: 649px)">
        {(matches) => (
          <div className="block">
            {matches ? mobileStepper : desktopButtons}
          </div>
        )}
      </Media>
    </div>
  );
};

WizardNavigation.propTypes = {
  wizardContext: PropTypes.shape({
    pages: PropTypes.arrayOf(PropTypes.node.isRequired).isRequired,
    activePage: PropTypes.number.isRequired,
    setPreviousPage: PropTypes.func.isRequired
  }),
  previousLabel: PropTypes.node.isRequired,
  nextLabel: PropTypes.node.isRequired,
  submitLabel: PropTypes.node.isRequired
};

export const WizardNavigationInternal = WizardNavigation;

export default withWizardContext(WizardNavigation);
