import axios from 'axios';
import _ from 'lodash';
import { toast } from 'react-toastify';

// This interceptor will show an error toast on each failed request
// The message can be dynamic if the errorMessage parameter is a function

export const addToastInterceptor = (errorMessage) => {
  const createNiceErrorToast = (url, e) => {
    // eslint-disable-next-line no-console
    console.error(url, e);

    if (_.isFunction(errorMessage)) {
      toast.error(
        errorMessage(e)
      );
    } else {
      toast.error(errorMessage);
    }
  };

  axios.interceptors.response.use(
    response => response,
    error => {
      const { request, response } = error;

      if (request.status === 400) {
        if (_.isArray(response.data)) {
          response.data.forEach(elem => {
            let type = null;
            if (elem.type === 'ERROR') {
              type = toast.TYPE.ERROR;
            } else if (elem.type === 'WARNING') {
              type = toast.TYPE.WARNING;
            } else {
              type = toast.TYPE.INFO;
            }

            toast(`${elem.fieldName}: ${elem.message}`, { type });
          });
        } else {
          createNiceErrorToast(request.responseURL, error);
        }
      } else if (response.status === 404) {
        toast.error(error.message);
      } else {
        createNiceErrorToast(request.responseURL, error);
      }

      return Promise.reject(error);
    }
  );
};

export const removeToastInterceptor = (interceptor) => {
  if (interceptor) {
    axios.interceptors.response.eject(interceptor);
  }
};
