import { combineReducers } from 'redux';
import fuelSavings from './fuelSavingsReducer';
import boardingReducer from './boardingReducer';
import referralReducer from './referralReducer';

const rootReducer = combineReducers({
  fuelSavings,
  boarding: boardingReducer,
  referral: referralReducer
});

export default rootReducer;
