import { format } from "date-fns";
import agent from "../../api/agent";
import { makeAutoObservable, runInAction } from "mobx";

class CalculatorStore {
  selectedActivity = undefined;
  editMode = false;
  loading = false;
  loadingInitial = false;
  isClicked = true;
  isPlayed = false;
  isModal = false;
  isSideMoney = false;
  isImageCalculator = true;
  isSideCouple = false;
  isSidePolitical = false;
  isModalOctober = false;

  constructor() {
    makeAutoObservable(this);
  }

  //   get activitiesByDate() {
  //     return Array.from(this.activityRegistry.values()).sort
  //       ((a, b) => {
  //         console.log(a.date);
  //         a.date = new Date(a.date!);
  //         b.date = new Date(b.date!);
  //         return a.date!.getTime() - b.date!.getTime()
  //       } );
  //   }

  //   get groupedActivities() {
  //     return Object.entries(
  //       this.activitiesByDate.reduce((activities, activity) => {
  //         const date = format(activity.date,'dd MMM yyyy h:mm aa')
  //         activities[date] = activities[date]
  //           ? [...activities[date], activity]
  //           : [activity];
  //         return activities;
  //       }, {})
  //     );
  //   }

  //   loadActivities = async () => {
  //     this.loadingInitial = true;
  //     try {
  //       const activities = await agent.Activities.list();

  //       runInAction(() => {
  //         activities.forEach(activity => {
  //           this.activityRegistry.set(activity.id, activity);
  //           this.setActivity(activity);
  //          })
  //          this.loadingInitial = false;
  //       })

  //     } catch (error) {
  //       runInAction(() => {
  //       this.loadingInitial = false;
  //     })
  //   }
  // }

  // loadActivity = async (id) => {
  //   let activity = this.getActivity(id);
  //   if (activity) {
  //     this.selectedActivity = activity;
  //     return activity;
  //   } else {
  //     this.loadingInitial = true;

  //   try {
  //     activity = await agent.Activities.details(id);
  //     this.setActivity(activity);
  //     runInAction(() => {
  //       this.selectedActivity = activity;
  //       this.loadingInitial = false;
  //       return activity;
  //     });

  //     return activity;
  //   } catch (error) {
  //     console.log(error);
  //     this.loadingInitial = false;
  //   }
  // }
  // };

  //  getActivity = (id) => {
  //   return this.activityRegistry.get(id);
  // };

  //   setActivity(activity){
  //   const user = store.userStore.user;
  //       if (user) {
  //           activity.isGoing = activity.attendees.some(
  //               a => a.username === user.username
  //           )
  //           activity.isHost = activity.hostUsername === user.username;
  //           activity.host = activity.attendees.find(x => x.username === activity.hostUsername);
  //       }

  //   activity.date = new Date(activity.date);
  //   this.activityRegistry.set(activity.id, activity);
  // };

  setIsClicked = (click) => {
    this.isClicked = click;
  };

  setIsPlayed = (click) => {
    this.isPlayed = click;
  };

  setIsModal = (click) => {
    this.isModal = click;
  };

  setIsModalOctober = (click) => {
    this.isModalOctober = click;
  };

  calculateForm = async (formData) => {
    try {
      console.log("u Data sam");
      let data;
      await agent.Calculator.calculate(formData).then(
        (response) => (data = response)
      );
      console.log("DaTA iz CSTORa", data);
      return data;

      runInAction(() => {
        if (data !== null) {
          this.isClicked = false;
        }
        console.log("DATA", data);
        return data;
      });
    } catch (error) {
      console.log(error);
    }
  };

  //    createActivity = async (activity) => {
  //     const user = store.userStore.user;
  //     const attendee = new Profile(user);
  //     try {
  //         await agent.Activities.create(activity);
  //         const newActivity = new Activity(activity);
  //         newActivity.hostUsername = user.username;
  //         newActivity.attendees = [attendee];
  //         this.setActivity(newActivity);
  //         runInAction(() => {
  //             this.selectedActivity = newActivity;
  //         })
  //     } catch (error) {
  //         console.log(error);
  //     }
  // }

  // cancelActivityToggle = async () => {
  //   this.loading = true;
  //   try {
  //       await agent.Activities.attend(this.selectedActivity.id);
  //       runInAction(() => {
  //           this.selectedActivity.isCancelled = !this.selectedActivity.isCancelled;
  //           this.activityRegistry.set(this.selectedActivity.id, this.selectedActivity);
  //       })
  //   } catch (error) {
  //       console.log(error);
  //   } finally {
  //       runInAction(() => this.loading = false);
  //   }
  // }

  // updateActivity = async (activity) => {
  //   try {
  //       await agent.Activities.update(activity);
  //       runInAction(() => {
  //           if (activity.id) {
  //               let updatedActivity = {...this.getActivity(activity.id), ...activity}
  //               this.activityRegistry.set(activity.id, updatedActivity);
  //               this.selectedActivity = updatedActivity;
  //           }
  //       })
  //   } catch (error) {
  //       console.log(error);
  //   }
  // }

  //     deleteActivity = async (id) => {
  //     this.loading = true;
  //     try {
  //       await agent.Activities.delete(id);
  //       runInAction(() => {
  //         this.activityRegistry.delete(id);
  //         this.loading = false;
  //       });
  //     } catch (error) {
  //       console.log(error);
  //       runInAction(() => {
  //         this.loading = false;
  //       });
  //     }
  //   };

  //   updateAttendance = async () => {
  //     const user = store.userStore.user;
  //     this.loading = true;
  //     try {
  //         await agent.Activities.attend(this.selectedActivity.id);
  //         runInAction(() => {
  //             if (this.selectedActivity.isGoing) {
  //                 this.selectedActivity.attendees =
  //                     this.selectedActivity.attendees.filter(a => a.username !== user.username);
  //                 this.selectedActivity.isGoing = false;
  //             } else {
  //                 const attendee = new Profile(user);
  //                 this.selectedActivity.attendees.push(attendee);
  //                 this.selectedActivity.isGoing = true;
  //             }
  //             this.activityRegistry.set(this.selectedActivity.id, this.selectedActivity)
  //         })
  //     } catch (error) {
  //         console.log(error);
  //     } finally {
  //         runInAction(() => this.loading = false);
  //     }
  // }

  // cancelActivityToggle = async () => {
  //   this.loading = true;
  //   try {
  //       await agent.Activities.attend(this.selectedActivity!.id);
  //       runInAction(() => {
  //           this.selectedActivity!.isCancelled = !this.selectedActivity?.isCancelled;
  //           this.activityRegistry.set(this.selectedActivity!.id, this.selectedActivity!);
  //       })
  //   } catch (error) {
  //       console.log(error);
  //   } finally {
  //       runInAction(() => this.loading = false);
  //   }
  // }

  // updateAttendeeFollowing = (username: string) => {
  //   this.activityRegistry.forEach(activity => {
  //       activity.attendees.forEach(attendee => {
  //           if (attendee.username === username) {
  //               attendee.following ? attendee.followersCount-- : attendee.followersCount++;
  //               attendee.following = !attendee.following;
  //           }
  //       })
  //   })
  // }

  clearSelectedActivity = () => {
    this.selectedActivity = undefined;
  };

  //  createActivity = async (activity: Activity) => {
  //     this.loading = true;
  //     try {
  //       await agent.Activities.create(activity);
  //       runInAction(() => {
  //         this.activityRegistry.set(activity.id, activity);
  //         this.selectedActivity = activity;
  //         this.editMode = false;
  //         this.loading = false;
  //       });
  //     } catch (error) {
  //       console.log(error);
  //       runInAction(() => {
  //         this.loading = false;
  //       });
  //     }
  //   };

  // updateActivity = async (activity: Activity) => {
  //   this.loading = true;
  //   try {
  //     await agent.Activities.update(activity);
  //     runInAction(() => {
  //       this.activityRegistry.set(activity.id, activity);
  //       this.selectedActivity = activity;
  //       this.editMode = false;
  //       this.loading = false;
  //     });
  //   } catch (error) {
  //     console.log(error);
  //     runInAction(() => {
  //       this.loading = false;
  //     });
  //   }
  // };

  // deleteActivity = async (id: string) => {
  //   this.loading = true;
  //   try {
  //     await agent.Activities.delete(id);
  //     runInAction(() => {
  //       this.activityRegistry.delete(id);
  //       this.loading = false;
  //     });
  //   } catch (error) {
  //     console.log(error);
  //     runInAction(() => {
  //       this.loading = false;
  //     });
  //   }
  // };
}

const calculatorStore = new CalculatorStore();

export default calculatorStore;
export { CalculatorStore };
