import iban from 'iban';
import moment from 'moment';

export const required = (message) => (value) =>
  ((value !== null && value !== undefined && ((!value.trim) || value.trim())) ? undefined : message);
//  (value ? undefined : 'Required');

export const isPositive = (message) => (value) => (Number(value) > 0 ? undefined : message);

export const maxLength = (max, message) => (value) =>
  ((value && value.length > max) ? message : undefined);

export const minLength = (min, message) => (value) =>
  ((value && value.length < min) ? message : undefined);

export const hasLength = (length, message) => (value) =>
  ((value && value.length !== length) ? message : undefined);

export const number = (message) => (value) => {
  const retVal = (value.size > 0 && !/^\d+$/.test(targetValue)) ? message : undefined;
  return retVal;
};

export const noSpecialCharacters = (message) => (value) =>
  ((value && (/([$&§+:;/_±~=?@|\\{}<>^*()%!\[\]])/i).test(value)) // eslint-disable-line no-useless-escape
    ? message
    : undefined);

export const validCroatianIban = (message) => (value) =>
  (iban.isValid(value) && value.toLowerCase().indexOf('hr') > -1
    ? undefined
    : message);

export const validSlovenianVAT = (message) => (value) => {

  if (value.length == 8) {
    
    var sum = 0;
    var ponder = 8;
    
    for (var i = 0; i < 7; i++) {
      sum += ponder * value.charAt(i);
      ponder--;
    }

    var controlDigit = (11 - (sum % 11)) % 10
    return controlDigit == value.charAt(7) ? undefined : message
  }
  
  return message;
}

export const minValue = (min, message) => (value) =>
  ((value && value < min) ? message : undefined);

export const email = (message) => (value) =>
  ((value && !(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i).test(value))
    ? message
    : undefined);

export const alphaNumeric = (message) => (value) =>
  ((value && (/[^a-zA-Z0-9 ]/i).test(value))
    ? message
    : undefined);

export const phoneNumber = (message) => (value) =>
  ((value && !(/^(0|[1-9][0-9]{9})$/i).test(value))
    ? message
    : undefined);

export const validDateString = (message) => (value) =>
  ((value !== undefined && (value === null || moment(value).isValid())) ? message : undefined);


export const validDate = (message) => (value) =>
  ((value !== undefined && (value === null || !value.isValid())) ? message : undefined);

export const mustBeAfter = (date, message) => (value) =>
  ((value === undefined || date.diff(value, 'years') < 0) ? undefined : message);

export const valid = () => undefined;

export const alphanumUnderscore = (message) => (value) =>
  ((value && !(/^\w+$/i).test(value))
    ? message
    : undefined);

export const anyCharWithoutWhitespace = (message) => (value) =>
  ((value && !(/^\S+$/i).test(value))
    ? message
    : undefined);

export const cannotEndWithSpace = (message) => (value) =>
  ((value && value.trim().length !== value.length)
    ? message
    : undefined);

export const mustBeYes = (message) => (value) =>
  ((value !== null && value !== undefined && ((!value.trim) || value.trim()) && value.toLowerCase() === 'yes') ? undefined : message);

export const mustBeNo = (message) => (value) =>
  ((value !== null && value !== undefined && ((!value.trim) || value.trim()) && value.toLowerCase() === 'no') ? undefined : message);

export const composeValidators = (...validators) => value =>
  validators.reduce((error, validator) => error || validator(value), undefined);
