import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import {Grid} from '@material-ui/core';
import {FormattedMessage, injectIntl, intlShape} from 'react-intl';
import '../CardApplication/CardApplicationForm/style.scss';
import './style.scss';
import ReactGA from 'react-ga';
import ImageYes from '../../../resources/images/YES.svg';
import ImageAppStore from '../../../resources/images/app store badge.svg';
import ImageGooglePlay from '../../../resources/images/google play badge.svg';
import ImageMobileAppMockup from '../../../resources/images/mobile_app_mock_2.png';

class FormSuccessNew extends React.Component {
  static propTypes = {
    intl: intlShape.isRequired,
    clientName: PropTypes.string,
    clientCategory: PropTypes.string
  };

  componentDidMount ( ) {
    ReactGA.event({
      category: 'JADE',
      action: 'Request',
      label: 'Preliminary YES'
    });

    if (localStorage && localStorage.getItem('userId')) {
      localStorage.removeItem('userId');
    }
  }


  render( ) {
    const {clientCategory, clientName, intl} = this.props;

    return (
      <React.Fragment>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          alignContent={'space-around'}
          justify="center"
          style={{minHeight: '100vh'}}
        >
            <div className={'form-success-polygon'}>
              <img src={ImageYes} className={'success-icon'}/>
              <img src={ImageMobileAppMockup} className={'mobile-image'}/>
              <div className={'success-content'}>
                <Grid container spacing={24}>
                  <Grid item xs={12}>
                    <ReactMarkdown
                      className={'intltext header'}
                      source={intl.formatMessage({id: 'applicationForm.success.title'}, {'NAME': clientName})}
                      escapeHtml={false}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <ReactMarkdown
                      source={intl.formatMessage({id: 'applicationForm.success.subtitle'})}
                      escapeHtml={false}
                      className={'intltext pre-link'}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <div className={'store-link-container'}>
                      <div className={'store-link'}>
                        <a
                          href="https://apps.apple.com/app/d-mobile/id1495036034"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <FormattedMessage id="applicationForm.success.downloadIos">
                            {(message) => (
                              <img
                                src={ImageAppStore}
                                alt={message}
                                className="presignup-form__download-image"
                                width="95"
                              />
                            )}
                          </FormattedMessage>
                        </a>
                      </div>
                      <div className={'store-link'}>
                        <a
                          href="https://play.google.com/store/apps/details?id=slo.ecslovenia.ema"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <FormattedMessage id="applicationForm.success.downloadAndroid">
                            {(message) => (
                              <img
                                src={ImageGooglePlay}
                                alt={message}
                                className="presignup-form__download-image"
                                width="104"
                              />
                            )}
                          </FormattedMessage>
                        </a>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <ReactMarkdown
                      source={intl.formatMessage({id: 'applicationForm.success.subtitle2'})}
                      escapeHtml={false}
                      className={'intltext post-link'}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <div className={'intltext footer'}>
                      <FormattedMessage id={'applicationForm.success.list'}/>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <ReactMarkdown
                      source={intl.formatMessage({id: 'applicationForm.success.list.item1'})}
                      escapeHtml={false}
                      className={'intltext list-start'}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <ReactMarkdown
                      source={intl.formatMessage({id: `applicationForm.success.list.item.${clientCategory}`})}
                      escapeHtml={false}
                      className={'intltext'}
                    />
                  </Grid>
                </Grid>
              </div>
            </div>
        </Grid>
      </React.Fragment>
    );
  }
}

export default injectIntl(FormSuccessNew);
