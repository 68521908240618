import React from 'react';
import PropTypes from 'prop-types';
import { TextField, Tooltip } from '@material-ui/core';
import { fieldPropTypes } from '../../types/form';
import NumberFormat from 'react-number-format';
import classNames from 'classnames';
import InfoIcon from '@material-ui/icons/Info';

const NumberFormatCustom = (props) => {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      ref={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      isNumericString
      thousandSeparator=" "
      decimalSeparator="."
      suffix=" EUR"
    />
  );
};

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

// eslint-disable-next-line react/no-multi-comp
const EuroInput = ({
  input,
  label,
  placeholder,
  helperText,
  className,
  tooltip,
  meta: { touched, error },
  ...custom
}) => (
  <TextField
    label={<span>{label}</span>}
    error={touched && Boolean(error)}
    helperText={(touched && error) || helperText || ' '}
    placeholder={placeholder}
    FormHelperTextProps={{
      className: 'form-control__helper'
    }}
    InputLabelProps={{
      className: 'form-control__label',
      shrink: true
    }}
    InputProps={{
      inputComponent: NumberFormatCustom,
      className: 'form-control__input kuna',
      disableUnderline: true,
      autoComplete:'off'
    }}
    className={classNames('form-control', className)}
    {...input}
    {...custom}
  />
);

EuroInput.propTypes = {
  ...fieldPropTypes,
  label: PropTypes.node.isRequired,
  className: PropTypes.string,
  tooltip: PropTypes.node
};

export default EuroInput;
