import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

const HeaderCardImage = ({ className, ...restProps }) => (
  <div {...restProps} className={classNames('header-card__image', className)} />
);

HeaderCardImage.propTypes = {
  className: string
};

export default HeaderCardImage;
