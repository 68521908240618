import React from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
//import PropTypes from 'prop-types';
import { WizardContext } from './WizardContext';

const WizardStepper = () => (
  <WizardContext.Consumer>
    {({ pages, activePage, setActivePage, completedPages }) => (
      <Stepper nonLinear activeStep={activePage}>
        {pages.map((label, idx) => {
          const props = {};
          const labelProps = {};
          // if (this.isStepOptional(index)) {
          //   labelProps.optional = <Typography variant="caption">Optional</Typography>;
          // }
          // if (this.isStepSkipped(index)) {
          //   props.completed = false;
          // }
          return (
            <Step
              key={label}
              {...props}
            >
              <StepButton
                {...labelProps}
                onClick={() => setActivePage(idx)}
                completed={activePage === idx ? false : completedPages.indexOf(idx) !== -1}
                disabled={idx === 0 ? false : completedPages.indexOf(idx - 1) === -1}
              >{label}</StepButton>
            </Step>
          );
        })}
      </Stepper>
    )}
  </WizardContext.Consumer>
);

/*
WizardStepper.propTypes = {
  wizardContext: PropTypes.shape({
    activePage: PropTypes.number,
    pages: PropTypes.arrayOf(PropTypes.node.isRequired).isRequired,
    setActivePage: PropTypes.func,
    completedPages: PropTypes.arrayOf(PropTypes.number.isRequired)
  }).isRequired,
};
*/

export default WizardStepper;
