import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';
import Title from './HeaderCardTitle';
import Content from './HeaderCardContent';
import Image from './HeaderCardImage';
import './style.scss';

const HeaderCard = ({ className, ...restProps }) => (
  <div {...restProps} className={classNames('header-card', className)} />
);

HeaderCard.propTypes = {
  className: string
};

HeaderCard.Title = Title;
HeaderCard.Content = Content;
HeaderCard.Image = Image;

export default HeaderCard;
