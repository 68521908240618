import React from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormHelperText,
  Tooltip
} from '@material-ui/core';
import classNames from 'classnames';
import {fieldPropTypes} from '../../types/form';
import ExplanationModal from './ExplanationModal';
import './style.scss';
import calculatorStore from '../../stores/calculatorStore';

const changeImage = () => {
  console.log("Image :", calculatorStore.isSideCouple)
  calculatorStore.isSideCouple = false;
  calculatorStore.isImageCalculator = false;
  calculatorStore.isSidePolitical = false;
  calculatorStore.isSideMoney = true;
}

const onChange = (props, originalOnChange) => (e) => {

console.log("On change :", props.input.name)
  if (props.input.name === 'employeeType') {

    changeImage();
    props.context.updateValue('employeeType', e.target.value);
    props.context.updateValue('status', e.target.value);
  }
  if (props.input.name === 'isPEP') {
    changeImage();
    props.context.updateValue('employeeType', 'political');
    calculatorStore.isSidePolitical = true;
    calculatorStore.isSideMoney = false;
    if(e.target.value === 'yes') {
      props.context.updateValue('modalOpen', true);
    }
  }
  if (props.input.name === 'isSlovenian') {

    changeImage();
    if(e.target.value === 'no') {
      props.context.updateValue('modalSlovenianOpen', true);
    }
  }

  originalOnChange(e.target.value);
  console.log("calculatorStore.isSidePolitical :", calculatorStore.isSidePolitical)
};
class RadioGroupInput extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      modalOpen: false,
      modalSlovenianOpen: false
    };
  }

  handleOpen = () => this.setState({modalOpen: true, modalSlovenianOpen: true});

  handleClose = () => this.setState({modalOpen: false, modalSlovenianOpen: false});

  render() {
    const {
      input,
      label,
      helperText,
      meta: {touched, error},
      children,
      groupClassName,
      labelClassName,
      className,
      tooltip,
      modalContent,
      ...rest
    } = this.props;
    const {modalOpen,modalSlovenianOpen} = this.state;
    const labelContent = (
      <FormLabel
        component="legend"
        className={classNames('form-control__label', labelClassName)}
      >
        {label}
      </FormLabel>
    );

    return (
      <FormControl
        component="fieldset"
        error={touched && Boolean(error)}
        {...rest}
        className={classNames('', className)}
      >
        {modalContent && (
          <ExplanationModal title={label} content={modalContent} open={modalOpen} onClose={this.handleClose}/>
        )}
        {tooltip ? (
          <Tooltip title={tooltip} classes={{tooltip: 'form-control__tooltip'}}>
            {labelContent}
          </Tooltip>
        ) : (labelContent)}
        <RadioGroup
          {...input}
          onChange={onChange(this.props, input.onChange)}
          className={classNames('form-control__radio-group', groupClassName)}
        >
          {children}
        </RadioGroup>
        <FormHelperText className="form-control__helper">{(touched && error) || helperText || ' '}</FormHelperText>
      </FormControl>
    );
  }
}

RadioGroupInput.propTypes = {
  ...fieldPropTypes,
  label: PropTypes.node.isRequired,
  groupClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  className: PropTypes.string,
  tooltip: PropTypes.node,
  modalContent: PropTypes.node,
  helperText: PropTypes.node,
  context: PropTypes.shape({})
};

export default RadioGroupInput;
