import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Field } from 'react-final-form';
import { Typography } from '@material-ui/core';
import TextInput from '../../../../components/inputs/TextInput';
import {
  composeValidators,
  required,
  noSpecialCharacters,
} from '../../../../../utils/validators/translated';
import Grid from '@material-ui/core/Grid';

const AddressInformationFormPart = () => (
  <React.Fragment>
    <Grid container spacing={24}>
      <Grid item xs={12}>
        <Typography
          variant="subheading"
          className="subheading"
        >
          2. <FormattedMessage id="applicationForm.addressInformation.title" />
        </Typography>
      </Grid>
    </Grid>

    <Grid container spacing={24}>
      <Grid item xs={12} sm={6}>
        <Field
          component={TextInput}
          className="text-field"
          name="address"
          label={<FormattedMessage id="applicationForm.personalData.permanentAddress" />}
          validate={composeValidators(required, noSpecialCharacters)}
          validateFields={[]}
          required
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field
          component={TextInput}
          className="text-field"
          name="houseNumber"
          label={<FormattedMessage id="applicationForm.personalData.houseNumber" />}
          validate={composeValidators(required, noSpecialCharacters)}
          validateFields={[]}
          required
        />
      </Grid>
    </Grid>
    <Grid container spacing={24}>
      <Grid item xs={12} sm={6}>
        <Field
          component={TextInput}
          className="text-field"
          name="zipCode"
          label={<FormattedMessage id="applicationForm.personalData.zipCode" />}
          validate={composeValidators(required, noSpecialCharacters)}
          validateFields={[]}
          required
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field
          component={TextInput}
          className="text-field"
          name="city"
          label={<FormattedMessage id="applicationForm.personalData.city" />}
          validate={composeValidators(required, noSpecialCharacters)}
          validateFields={[]}
          required
        />
      </Grid>
    </Grid>
  </React.Fragment>
);

export default AddressInformationFormPart;
