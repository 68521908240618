import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Button, CircularProgress } from '@material-ui/core';
import { Form, FormSpy } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import createDecorator from 'final-form-focus';
import  { phonePrefix } from '../FormParts/WebPhaseFormPart';
import '../../CardApplication/CardApplicationForm/style.scss';
import '../../PreSignupForm/style.scss';
import ReactGA from 'react-ga';
import uuid from 'uuid/v4';
import { referrerDataPropTypes } from '../../../../react/types/form';
import PreSignupContext from '../PreSignpupContext';
import ModalPhaseFormPart from './ModalPhaseFormPart';
import calculatorStore  from '../../../stores/calculatorStore';
import config from '../../../../../config';

const helpUrl = 'mailto:dmobile@erstecard.si';
const initialValues = {
  citizenship: 191
};

const focusOnErrors = createDecorator();

class ModalForm extends React.Component {
  static propTypes = {


  };

  constructor (props) {
    super(props);
    this.initialValues = {
      referralCode: props.initialReferralCode,
      referralCode2: props.initialReferralCode2,
      submitting: false,
      ...initialValues
    };
    this.state = {
      options: [],
      isSubmitting: false,
    };
  }

  loadOptions = async (inputText, callback)=>{
    const response = await fetch(`${config.API_BASE_URL}/config`);
    const data = await response.json();
    const logData =  data.lists.filter(i => i.name == "InstalmentCalculatorEmploymentTypes");
    const arrayData = logData[0].values.map(item => item);
    return arrayData.map(i => ({label: i.name, value:i.id}));
  }

  componentDidMount = () => {

 this.loadOptions().then(response => {
  console.log('REsPonse1', response);
  this.setState({
    options: response,
    isSubmitting:false
  })});
  console.log('REsPonse2', this.state.options);

    if (localStorage && localStorage.getItem('userId')) {
      this.userId = localStorage.getItem('userId');
    } else {
      this.userId = uuid();
      if (localStorage) {
        localStorage.setItem('userId', this.userId);
      }
    }

    ReactGA.set({ userId: this.userId });
    ReactGA.pageview('/hr/zelim-diners');
  }

   handleSubmit = async (formData) => {


    // eslint-disable-next-line no-unused-vars
    const { termsAndConditions,referralCode,referralCode2,citizenship,submitting, ...restData } = formData;



    restData.userId = this.userId;

    // restData.phoneNumberCountryCode = '+386';
    // const phoneParts = restData.phoneNumberPrefix.split(' ');
    // restData.phoneNumberPrefix = phoneParts[1];
    // restData.phoneNumberCountryCode = phoneParts[0];

    restData.phoneNumberPrefix = restData.phoneNumberPrefix;
    restData.phoneNumberCountryCode = phonePrefix.replace(/\+/igm, '');

   /* const { dateOfBirthDay: day, dateOfBirthMonth: month, dateOfBirthYear: year } = restData;
    restData.dateOfBirth = moment({ years: year, months: month - 1, days: day });
    delete restData.dateOfBirthDay;
    delete restData.dateOfBirthMonth;
    delete restData.dateOfBirthYear;

    if (restData.dateOfBirth) {
      restData.dateOfBirth = moment(restData.dateOfBirth).add(12, 'hours');
    }*/

    let data;
     await calculatorStore.calculateForm(restData).then(response =>  data = response);
     this.setState({
       isSubmitting: true
     })
     console.log('RESTDATA', data);
     this.props.handleData(data);
     this.props.closeModal();
  }

  validateForm = (values) => {

    let errors = {};
    const { dateOfBirthDay: day, dateOfBirthMonth: month, dateOfBirthYear: year } = values;

    /*f (day && month && year) {
      const date = moment({ years: year, months: month - 1, days: day });

      if (!date.isValid()) {
        errors.dateOfBirthError = 'applicationForm.validation.invalidDate';
        errors.dateOfBirthDay = errors.dateOfBirthMonth = errors.dateOfBirthYear = null;
      } else if (moment().diff(date, 'years') < 18) {        errors = {};
        errors.dateOfBirthError = 'applicationForm.validation.over18';
        errors.dateOfBirthDay = errors.dateOfBirthMonth = errors.dateOfBirthYear = null;
      } else {
        errors = {};
        errors.dateOfBirthError = errors.dateOfBirthDay = errors.dateOfBirthMonth = errors.dateOfBirthYear = undefined;
      }
    }*/

    return errors;
  }

  render () {


    const { referrerData, referrerData2, getReferrerData, submitting } = this.props;

    return (
      <PreSignupContext.Consumer>
        {(context) => (<Form
          onSubmit={this.handleSubmit}
          decorators={[focusOnErrors]}
          initialValues={this.initialValues}
          subscription={{ values: true }}
          validate={this.validateForm}
        >
          {({ handleSubmit, form, values }) => (
            <form
              onSubmit={handleSubmit}
              className="card-application-form presignup-form no-negative"
            >
              <Grid container spacing={24}>
                <Grid item xs={12}>
                </Grid>
              </Grid>
              <div className="presignup-form__content_modal_calc">
                <ModalPhaseFormPart
                  change={form.change}
                  context={context}
                  values={values}
                  options={this.state.options}
                />

                  <div className="buttonModalDiv" >

                    <button
                      className="calculator-button"
                      type="submit"
                    >
                      {!this.state.isSubmitting &&
                        <FormattedMessage id={this.state.isValid ? "applicationForm.calculator.after.submit" : "applicationForm.calculator.before.submit"} />

                      }
                       {this.state.isSubmitting && (
      <CircularProgress className="progress" size={30}/>
    )}

                      <i className="modal-button-arrow" />
                    </button>
                    <div className="linkDiv">
                      <a className={'link-modal'} href={helpUrl}>

                        <div>
                          <FormattedMessage id="applicationForm.sideinfo.title.link" />
                        </div>
                      </a>
                    </div>
                  </div>



              </div>
            </form>
          )}
        </Form>)}
      </PreSignupContext.Consumer>
    );
  }
}


export default ModalForm;
