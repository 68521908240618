import {
  GET_REFERRER_DATA,
  GET_REFERRER_DATA_SUCCESS,
  GET_REFERRER_DATA_ERROR
} from '../actions/referralActions';

const initialState = {};

export default (state = initialState, action) => { // NOSONAR
  switch (action.type) {
    case GET_REFERRER_DATA:
      return {
        ...state,
        [action.codeType]: {
          code: action.referralCode,
          data: null,
          error: null
        }
      };

    case GET_REFERRER_DATA_SUCCESS:
      return {
        ...state,
        [action.codeType]: {
          code: action.referralCode,
          data: action.data,
          error: null
        }
      };

    case GET_REFERRER_DATA_ERROR:
      return {
        ...state,
        [action.codeType]: {
          code: action.referralCode,
          data: null,
          error: action.error
        }
      };

    default:
      return state;
  }
};
