import axios from 'axios';
import config from '../../config';

export const sendBoardingForm = (values) =>
  axios.post(`${config.API_BASE_URL}/application`, values);

export const sendPartialBoardingForm = (values) =>
  axios.post(`${config.API_BASE_URL}/initial-two-phase-application`, values);

export const getCountries = () =>
  axios.get(`${config.API_BASE_URL}/countries`);

export const getReferrerData = (referralCode, cancelToken) =>
  axios.get(`${config.API_BASE_URL}/referrers/${referralCode}`, {
    cancelToken
  });
