import React from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  InputAdornment
} from '@material-ui/core';
import MaskedInput from 'react-text-mask';
import classNames from 'classnames';
import {fieldPropTypes} from '../../types/form';
import './style.scss';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

const euroMask = createNumberMask({
  prefix: '',
  thousandsSeparatorSymbol: ' ',
  suffix: ' EUR'
});

const MaskedTextInputForEur = ({
                     input,
                     label,
                     placeholder,
                     helperText,
                     meta: {touched, error},
                     inputProps,
                     mask,
                     validationProps,
                     className,
                     tooltip,
                     context,
                     prefixText,
                     ...custom
                   }) => (
  <FormControl
    error={touched && Boolean(error)}
    {...custom}
    className={classNames('form-control', className)}
  >
    <InputLabel className="form-control__label" shrink htmlFor={custom.id}>{label}</InputLabel>
    <MaskedInput
      className={classNames("form-control__input", { 'with-type': inputProps && inputProps.type })}
      {...input}
      mask={euroMask}
      autoComplete={'off'}
      placeholder={placeholder}
      {...inputProps}
    />
    <FormHelperText className="form-control__helper">{(touched && error) || helperText || ' '}</FormHelperText>
  </FormControl>
);

MaskedTextInputForEur.propTypes = {
  ...fieldPropTypes,
  className: PropTypes.string,
  prefixText: PropTypes.string,
  label: PropTypes.node.isRequired,
  inputProps: PropTypes.shape({}),
  validationProps: PropTypes.shape({}),
  helperText: PropTypes.node,
  tooltip: PropTypes.node,
  placeholder: PropTypes.node,
  context: PropTypes.shape({})
};

export default MaskedTextInputForEur;
